import { faBan, faCheck, faCircle, faEdit, faEye, faPlus, faSearch, faTrash, faUserPlus, faUsers, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import permessi_fetch from '../fetch/permessi_fetch'
import SelezionaGruppi from '../gruppi/SelezionaGruppi';

import InfoModal from '../riutilizabili/InfoModal';
import PulsantiFiltra from '../riutilizabili/PulsantiFiltra';
import QuestionModal from '../riutilizabili/QuestionModal';
import Tabella from '../riutilizabili/Tabella';
import CampiPermessi from './CampiPermessi';
import CreaAssPermessoUser from './CreaAssPermessoUser';
import PaginazioneTitoloPulsanteCrea from '../riutilizabili/PaginazioneTitoloPulsanteCrea';


export default function ViewPermessi() {
    const [permessi , setPermessi] = useState([]);
    const [inputs, setInputs] = useState({});
    const [inputsFiltra, setInputsFiltra] = useState({});
    const [viewCreaPermesso, setViewCreaPermesso] = useState(false);
    const [viewPermesso, setViewPermesso] = useState(false);
    const [viewEditPermesso, setViewEditPermesso] = useState(false);
    const [viewUsersViewPermesso, setViewUsersViewPermesso] = useState(false);
    const [viewUserPlusPermesso, setViewUserPlusPermesso] = useState(false);
    const [viewUsersViewGruppo, setViewUsersViewGruppo] = useState(false);
    const [viewAddGroup, setViewAddGroup] = useState(false);
    const [datiAddGroup, setDatiAddGroup] = useState(false);
    const [idUserAssPermesso, setIdUserAssPermesso] = useState({});
    const [idUserAssPermessoStato, setIdUserAssPermessoStato] = useState('0');
    const [gruppiPermesso , setGruppiPermesso] = useState([]);
    const [error, setError] = useState({});

    useEffect( ()=>{
        readAllPermessi()
      },[])

    const navigate = useNavigate();

    const readAllPermessi = async () => {
        await permessi_fetch.getAll()
        .then(response => {
            //console.log(response.data);
            setPermessi(response.data)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const azzeraFiltra = async() => {
        await readAllPermessi();
        setInputsFiltra({})
    }

    const columns = React.useMemo(
        () => [

              {
                Header: 'Nome',
                accessor: 'nome',
              },
              {
                Header: 'Descrizione',
                accessor: 'descrizione',
              },
              {
                Header: 'Etichetta',
                accessor: 'url',
              }
        ],
        []
      )

    const handleChange = (name , value) => {
        //console.log(name , value);
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleChangeFiltra = (name , value) => {
        //console.log(name , value);
        setInputsFiltra(values => ({ ...values, [name]: value }))
        filtra({[name]: value})
    }

    const creaPermesso = async (stato) => {
        if (stato) {
            await permessi_fetch.create({...inputs , stato : 1})
            .then(response => {
                readAllPermessi()
                setViewCreaPermesso(false)
                setInputs({})
            })
            .catch(error=> {

                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)

                }
                //console.log(error.response.data , ' error');

            })
        } else {
            setViewCreaPermesso(false)
        }
    }

    const editPermesso = async (stato) => {
        if (stato) {
            await permessi_fetch.update(inputs)
            .then(response => {
                readAllPermessi()
                setViewEditPermesso(false)
                setInputs({})
            })
            .catch(error => {
                //console.log(error , ' error read user group');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)
    
                }
            })
        } else {
            setViewEditPermesso(false)
        }
    }
    const onChangeViewPermesso =async (id) => {
        await permessi_fetch.get({id : id})
        .then(response => {
            //console.log(response);
            setViewPermesso(response.data[0])
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }
    const onChangeEditPermesso =async (id) => {
        await permessi_fetch.get({id : id})
        .then(response=> {
            //console.log(response ,'response');
            setInputs(response.data[0])
            setViewEditPermesso(true)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }
    const eliminaPermesso = async (id, stato) => {
        const nuovoStato = stato === 1 ? '0' : '1'

            await permessi_fetch.update({
                id : id,
                stato : nuovoStato
            })
            .then(response => {
                //console.log(response);
                readAllPermessi()
            })
            .catch(error => {
                //console.log(error , ' error read user group');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)
    
                }
            })

    } 

    const Pulsanti = (id) => {
        //console.log(id.id.original.id , 'id');
        return(
            <td className=' p-0 m-0'>
                {/* <button className='btn py-0 my-0 button_fix' onClick={()=>onChangeViewPermesso(id.id.original.id)}>
                    <FontAwesomeIcon className=' p-0 m-0 text_bluDue' icon={faEye}/>
                </button>

                <button className='btn py-0 my-0 button_fix' onClick={() =>onChangeEditPermesso(id.id.original.id)}>
                    <FontAwesomeIcon className=' p-0 m-0 text_bluDue' icon={faEdit} />
                </button> */}

                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' p-0 m-0 text_bluDue' icon={faUsers} onClick={()=>readPermessi(id.id.original)} />
                </button>

                {/* <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' p-0 m-0 text_bluDue' icon={faUserPlus} onClick = {()=>setViewUserPlusPermesso(id.id.original.id)}/>
                </button>

                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' p-0 m-0 text_bluDue' icon={faUsersGear} onClick = {()=>onChangeViewgruppiPermessi(id.id.original.id , id.id.original.nome)}/>
                </button> */}

                <button className='btn py-0 my-0 button_fix' >
                    {id.id.original.stato ?
                    <FontAwesomeIcon className=' p-0 m-0 text-danger' icon={faTrash} onClick = {()=>eliminaPermesso(id.id.original.id , id.id.original.stato)}/>
                    :
                    <FontAwesomeIcon className=' p-0 m-0 text-success' icon={faCheck} onClick = {()=>eliminaPermesso(id.id.original.id , id.id.original.stato)}/>
                    }
                </button>
            </td>
        )
    }

    const filtra = async (param) => {
        await permessi_fetch.get({...inputsFiltra , ...param})
        .then(response => {
            setPermessi(response.data)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const onChangeCreaAssPermessoUser = async (stato) => {
        // viewUserPlusPermesso
        // idUserAssPermesso
        //console.log(viewUserPlusPermesso , 'viewUserPlusPermesso');
        //console.log(idUserAssPermesso , 'idUserAssPermesso');
        console.log(parseInt(idUserAssPermessoStato) , 'idUserAssPermessoStato');
        if (stato) {
            await permessi_fetch.createAssociazione({
                permessoId : viewUserPlusPermesso,
                userId : idUserAssPermesso ,
                stato : parseInt(idUserAssPermessoStato)
            })
            .then(response => {
                //console.log(response , ' response');
                setViewUserPlusPermesso(false)
            })
            .catch(error => {
                //console.log(error , ' error read user group');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)
    
                }
            })
        }else {
            //console.log('qui');
            setViewUserPlusPermesso(false)
        }
    }

    const readPermessi = async (data) => {
        console.log(data , ' data');
        const utenti = await permessi_fetch.getAssociazioneName({permessoId : data.id})
        const gruppi = await permessi_fetch.getAssociazioneGruppo({permessoId : data.id})
        setViewUsersViewPermesso(utenti.data)
        setGruppiPermesso(gruppi.data)
        setViewUsersViewGruppo({nome : data.nome})
        
    }

    const deleteAssocizioneGruppo = async(id) => {
        await permessi_fetch.updateAssociazioneGruppo({
            stato : 0,
            id : id
        })
        .then(async(response) => {
            //console.log(response , ' response');
            onChangeViewgruppiPermessi(viewUsersViewGruppo.id , viewUsersViewGruppo.nome)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const deleteAssocizione = async(id) => {
        await permessi_fetch.updateAssociazione({
            stato : 2,
            id : id
        })
        .then(response => {
            //console.log(response , ' response');
            readPermessi({
                permessoId :response?.data?.permessoId
            })
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const negaAssocizione = async(id) => {
        await permessi_fetch.updateAssociazione({
            stato : 0,
            id : id
        })
        .then(response => {
            //console.log(response , ' response');
            readPermessi({
                permessoId :response?.data?.permessoId
            })
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const abilitaAssocizione = async(id) => {
        await permessi_fetch.updateAssociazione({
            stato : 1,
            id : id
        })
        .then(response => {
            //console.log(response , ' response');
            readPermessi({
                permessoId :response?.data?.permessoId
            })
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const onChangeViewgruppiPermessi = async (id, nome) => {
        //console.log(id , ' permesso id');
        await permessi_fetch.getAssociazioneGruppo({permessoId : id})
        .then(response => {

            setGruppiPermesso(response.data)
            
            setViewUsersViewGruppo({nome : nome , id : id})
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })


    }

    const aggiungiPermessoGruppo = async (id) => {

        await permessi_fetch.createAssociazioneGruppo({
            gruppoId : id ,
            permessoId : viewUsersViewGruppo.id ,
            stato : 1
        })
        .then(response => {
            setViewAddGroup(false)
            onChangeViewgruppiPermessi(viewUsersViewGruppo.id , viewUsersViewGruppo.nome)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
        
    }
    
    
    console.log(viewUsersViewPermesso , ' viewUsersViewPermesso');
    //console.log(datiAddGroup , ' datiAddGroup');
    //console.log(gruppiPermesso , ' gruppiPermesso');
    return(
        <div className='p-2'>

            <PaginazioneTitoloPulsanteCrea
              titolo={'Permessi'}
              funCrea={() =>setViewCreaPermesso(true)}
            />

            <div className='pt-4 mt-5 px-3'>
                <div className='border_filtra p-3 mx-5 px-4' >
                    <CampiPermessi value={inputsFiltra} onChange={handleChangeFiltra} errors={{}} classiDiv={'d-flex align-items-center'} />
                    <div className='ps-2'>
                        <PulsantiFiltra
                            filtra={filtra}
                            azzera={azzeraFiltra}
                        />
                    </div>

                </div>
                <div className='p-4 mt-1' >
                    <Tabella data={permessi} columns={columns} Pulsanti={Pulsanti} />
                </div>
            </div>


            <div>
                {viewCreaPermesso && <QuestionModal 
                    title = 'Crea permesso'
                    text = {<CampiPermessi value={inputs} onChange={handleChange} errors={error} classiDiv={'d-flex align-items-center'} />}
                    YesLabel = 'Crea'
                    onClose = {creaPermesso}
                />}
                {viewPermesso && <InfoModal 
                    title='Leggi anagrafica permesso'
                    text = {
                        <div>
                            <p><span className='fw-bold'>Nome : </span>{viewPermesso.nome}</p>
                            {viewPermesso.url && <p><span className='fw-bold'>URL : </span>{viewPermesso.url}</p>}
                            <p><span className='fw-bold'>Descrizione : </span>{viewPermesso.descrizione}</p>
                        </div>
                    }
                    canClose={true}
                    handleClose={() =>setViewPermesso(false)}
                />}
                {viewEditPermesso && <QuestionModal 
                    title = 'Modifica permesso'
                    text = {<CampiPermessi value={inputs} onChange={handleChange} errors={error} classiDiv={'d-flex align-items-center'} />}
                    YesLabel = 'Modifica'
                    onClose = {editPermesso}
                />}

                {viewUserPlusPermesso && <QuestionModal 
                    title = 'Associa permesso ad utente'
                    text = {<CreaAssPermessoUser id={viewUserPlusPermesso} onChangeUser={(userId) => setIdUserAssPermesso(userId)} onChangeStato={(stato) => setIdUserAssPermessoStato(stato)}/> }
                    YesLabel = 'Crea associazione'
                    onClose = {(stato)=>onChangeCreaAssPermessoUser(stato)}
                />}

                {viewUsersViewPermesso && <InfoModal 
                    size='xl'
                    title={
                        <p className='p-0 m-0 ps-3'>
                            {'Utenti con il permesso '+ viewUsersViewGruppo.nome}
                        </p>
                    }
                    text={
                        <div className='ps-3' >
                            <h3 className='mt-5' > Assegnato agli utenti : </h3>
                            {viewUsersViewPermesso.length > 0 ? 
                            <ul>
                                {viewUsersViewPermesso.map(item => <li> {item.utente} {item.stato ===1 ? '': '(Negato)'} </li>)}
                            </ul>
                            :
                            ' Nessuno'
                            }
                             <h3 className='mt-5 pt-5' >Assegnato ai gruppi : </h3>
                             {gruppiPermesso.length > 0 ? 
                                <ul>
                                    {gruppiPermesso.map(item => <li title={item?.infoGruppo?.gruppoUser.map(element => element?.userAn?.username )} > {item.infoGruppo.nome} {item.stato ===1 ? '': '(Negato)'}</li>)}
                                </ul>
                            :
                            'Nessuno'
                            }
                        </div>
                    }
                    handleClose={()=>setViewUsersViewPermesso(false)}
                    canClose={true}
                    
                />}

                
                
            </div>
    
        </div>
    )
}