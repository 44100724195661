import React , { useEffect, useState } from "react";
import CampiSezioni from "./CampiSezioni";
import sezioneService from '../fetch/classSezione'
import QuestionModal from "../riutilizabili/QuestionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faBoxesStacked, faCheck, faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import Tabella from "../riutilizabili/Tabella";
import TabellaProdotti from "../prodotti/TabellaProdotti";
import InfoModal from "../riutilizabili/InfoModal";
import FiltraSezioni from "./FiltraSezioni";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import Switch2 from "../riutilizabili/Switch2";
import classSezione from "../fetch/classSezione";
import file_fetch from "../fetch/file_fetch";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import ProdottiSezione from "./ProdottiSezione";


export default function CreaSezione () { 
    const [inputs , setInputs] = useState({mobile : 0})
    const [inputsFiltra , setInputsFiltra] = useState({mobile : 0})
    const [errors , setErrors] = useState({})
    const [arraySez , setArraySez] = useState([])
    const [viewCreaSez , setViewCreaSez] = useState(false)
    const [viewModSez , setViewModSez] = useState(false)
    const [viewMostraProdSez , setViewMostraProdSez] = useState(false)
    const [arrayProdSez , setArrayProdSez] = useState([])
    const [disablePulsanteCrea , setDisablePulsanteCrea] = useState(true)
    const [viewInventario , setViewInventario] = useState(false)
    const [viewSezione , setViewSezione] = useState(false)
    const [magInventario , setMagInventario] = useState([])
    
    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    useEffect(()=>{readSez()},[])
    useEffect(()=>{verificaCampiObbligatoriCrea()},[inputs])

    const onChange = (nome ,valore)=>{
        setInputs(values => ({ ...values, [nome]: valore }))
        verificaCampiObbligatoriCrea()
    }

    const azzeraFiltra = () => {
        readSez()
        setInputs({})
    }

    const onChangeFiltra = (nome ,valore)=>{
        setInputsFiltra(values => ({ ...values, [nome]: valore }))
        filtraSezioni({[nome]: valore})
    }
    //console.log(inputs , ' inputs');

    const creaSez = async ()=> {
        let tmp = {...inputs}
        delete tmp.Prod_posizione_seziones
        const sez = await sezioneService.create(tmp)
        .then(response => {
            //console.log(response , ' response');
            return response
        })
        .catch(error => {
            //console.log(error , ' error');
            return error
        })
        return sez
    }

    const readSez = async () => {
        const sezioni = await sezioneService.get({stato : 1 ,pageSize , page,})
        //console.log(sezioni , ' sezionis');
        setInputsFiltra({})
        setArraySez(sezioni.data.data)
        setPageTot(sezioni.data.pageTot)
        setFu(fu+1)
    }

    const filtraSezioni = async (param) => {
        const sezioni = await sezioneService.get({...inputsFiltra ,pageSize , page, ...param})
        //console.log(sezioni , ' sezionis');
        setArraySez(sezioni.data.data)
        setPageTot(sezioni.data.pageTot)
        setFu(fu+1)
    }

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtraSezioni({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }


    const onCloseCreate = async (stato) => {
        if (stato) {
            const sezione = await creaSez();
            if (sezione.status === 200) {
                await readSez()
                setViewCreaSez(false)
                setErrors({})
            }else {
                setErrors(sezione.data)
            }
        } else {
            setViewCreaSez(false)
            
        }
        setInputs({})
    }

    const onCloseModifica = async (stato) => {
        if (stato) {
            const sezione = await creaSez();
            //console.log(sezione , ' sezione mod');
            if (sezione.status === 200) {
                await readSez()
                setViewModSez(false)
                setInputs({})
            }else {
                setErrors(sezione.data)
            }
        } else {
            setViewModSez(false)
            setInputs({})
            
        }
    }

    const columns = [
            {
                id : 1,
                label: 'Nome',
                nome: 'nome',
                altCol: (item)=> {
                    return(
                        <span className={item.sezionePadre === null  ? 'fw-bold' : ''} >{item.nome} </span>
                    )
                }
            },
            {
                id : 2,
                label: 'Descrizione',
                nome: 'descrizione',
            },
            {
                id : 3,
                label: 'Capienza/Portata',
                nome: 'capienza',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item?.capienza === 1000000000 ? 'Illimitato' : item.capienza}
                        </>
                    )
                }
            },
            {
                id : 4,
                label: 'Unita misura',
                nome: 'unitaMisura',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item?.UnitaMisura?.nome}
                        </>
                    )
                }
            },
            {
                id : 5,
                label: 'Tipo Magazzino',
                nome: 'mobile',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item.mobile === 1 ? 'Magazzino mobile' : 'Magazzino fisico'}
                        </>
                    )
                }
            },
            {
                id : 6,
                label: 'rfid',
                nome: 'rfid',
            },
            {
                id : 7,
                label: 'Spazio modulare',
                nome: 'modulare',
                altCol: (item)=> {
                    //console.log(original , 'original');
                    return(
                        <>
                            {item.modulare === 1 ? <> <FontAwesomeIcon icon={faCheck} />  </> : <FontAwesomeIcon icon={faBan} />}
                        </>
                    )
                }
            },
            {
                id : 8,
                label: 'Magazzino padre',
                nome: 'sezionePadre',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item.sezionePadre === null ? 'Nessuno' 
                            : 
                            item.sezionePadre.nome
                            }
                        </>
                    )
                }
            },
    ]

    const mostraModifica = (element)=> {
        let dati = {...element}
        dati.nomeSezionePadre = dati?.sezionePadre?.nome;
        dati.nomeUnitaMisura = dati?.UnitaMisura?.nome; 
        //console.log(dati, 'dati');
        setInputs(dati);
        verificaCampiObbligatoriCrea()
        setErrors({})
        setViewModSez(true)
    }

    const onCloseMostraProd = () => {
        setViewMostraProdSez(false)
    }

    const mostraProdSez = (element) => { 
        console.log(element , ' element');
        
        const prodotti = [...element.Prod_posizione_seziones]
        //console.log(element , ' element');
        let prodottiAttivi =[]
        prodotti.forEach(prod => {
            if (prod.stato === 1) {
                prodottiAttivi.push(prod)
            }
        });
        //console.log(element , ' arrayProdSez 2');

        setArrayProdSez({prodotti : prodottiAttivi , sezioneFigli : element.sezioniFigli , nome : element.nome})
        setViewMostraProdSez(true)
    }

    const onChangeValueSelezionaMg = (stato , id) => {
        let tmp =[...magInventario]
        if (!!stato) {
            tmp.push(id)
        }else {
            const posizione = tmp.indexOf(id);
            tmp.splice(posizione , 1)
        }
        setMagInventario(tmp)
    }
    
    const Pulsanti = ({element}) => {
        //console.log(element , ' element');
        return(
            <td className=" my-0 py-0 d-flex">
                <button className='btn button_fix m-0 p-0 mx-1' >
                    <FontAwesomeIcon className='fs-6 m-0 p-0' onClick={()=>setViewSezione(element)} icon={faEye} />
                </button>
                <button className='btn button_fix m-0 p-0  mx-1' >
                    <FontAwesomeIcon className='fs-6 m-0 p-0' title="modifica sezione/magazzino" icon={faEdit} onClick={async () =>await mostraModifica(element)} />
                </button>
                {/* <button className='btn button_fix my-0 py-0' >
                    <FontAwesomeIcon className='fs-6 my-0 py-0' title="visualizza sezioni/prodotti" icon={faBoxesStacked} onClick={async () =>await mostraProdSez(element)} />
                </button> */}
                <ProdottiSezione sezioneId={element.id} nomeSezione={element.nome} icona={<FontAwesomeIcon icon={faBoxesStacked} />} />
            </td>
        )
    }
    
    const PulsantiSeleziona = ({element}) => {

        //console.log(element , ' element');
        return(
            <td>
                <Switch2
                    stato={magInventario.includes(element.id)}
                    number={element.id}
                    onChangeValue={onChangeValueSelezionaMg}
                />
            </td>
        )
    }

    const mostraSottoSezione = async (id) => {
        setViewMostraProdSez(false)
        setArrayProdSez({})
        const sezioni = await sezioneService.get({id : id});
        mostraProdSez(sezioni.data[0])

    }

    const verificaCampiObbligatoriCrea = () => {
        const campi = [
            'umId',
            'nome',
        ]
        const error = funzioniRiccorrenti.validazioneCampi(campi , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            setErrors(error)
            setDisablePulsanteCrea(true)
        }else {
            setErrors({})
            setDisablePulsanteCrea(false)
        }
        //console.log(inputs , ' inputs ver');
        // const error = funzioniRiccorrenti.verificaCampiObbligatori(Object.keys(inputs) ,campi);
        // if (error === true) {
        //     setErrors({})
        //     setDisablePulsanteCrea(false)
        // }else {
        //     setErrors(error)
        //     setDisablePulsanteCrea(true)
        // }
    }
    //console.log(arrayProdSez , ' arrayProdSezs');
    console.log(viewSezione , ' viewSezione');
        
    const azzera = async ()  =>{
        setInputsFiltra({qtDisp :1})
        setAzzeraVar(1)
        await filtraSezioni({pageSize , page:1 ,qtDisp : 1})
    }

    const esportaDati = async () => {
        //await classSezione.getInventarioMg({magazzini : magInventario})
        await file_fetch.scaricaInventario({magazzini : magInventario})
    }
    return( 
        <div className='pt-2'>
                <PaginazioneTitoloPulsanteCrea titolo={'Magazzini o sezioni'} funCrea={()=>{setViewCreaSez(true); verificaCampiObbligatoriCrea()}} />

            <div className="w-100">

                <div className="w-100 m-4 ps-4 pe-5 ">
                    <div style={{marginRight : ' 2rem' ,marginTop :'2.4rem'}}>
                        <div className="border_filtra p-3 my-5 ms-4 w-100 " >
                            <FiltraSezioni 
                                value={inputsFiltra}
                                onChange={onChangeFiltra}
                            />
                            <div className="ms-4">
                                <PulsantiFiltra
                                    filtra={filtraSezioni}
                                    azzera={azzera}
                                />
                            </div>
                        </div>
                    </div>
                        
                    <div className="m-4 mb-5" >
                        <button 
                            className="btn btn-primary" 
                            onClick={()=>{setViewInventario(true);azzera()}}
                        >
                            Scarica invetario magazzini
                        </button>
                    </div>

                    <div className="w-100 ms-2 ps-1 pe-5">
                        <TabellaPaginazione
                            colonne={columns}
                            dati={arraySez}
                            totalePag={pageTot}
                            azzera={azzeraVar}
                            impaginazione={impaginazione}
                            fu={fu}
                            Pulsanti={Pulsanti}
                        />
                    </div>

                </div>

                {/* <Tabella
                    data={arraySez}
                    columns={columns}
                    Pulsanti={Pulsanti}
                /> */}


                {viewCreaSez && <QuestionModal
                    title='Crea magazzino/sezione'
                    text={
                        <div>
                            <CampiSezioni      
                                values={inputs}
                                onChange={onChange}
                                errors={errors}
                                classiDiv=' d-flex flex-wrap'
                            />
                        </div>
                    }
                    YesLabel='Crea'
                    yesDisabled={disablePulsanteCrea}
                    onClose={onCloseCreate}
                />}

                {viewModSez && <QuestionModal
                    title='Modifica magazzino/sezione'
                    text={
                        <div>
                            <CampiSezioni
                                values={inputs}
                                onChange={onChange}
                                errors={errors}
                                classiDiv=' d-flex flex-wrap'
                            />
                        </div>
                    }
                    YesLabel='Modifica'
                    onClose={onCloseModifica}
                />}

                {!!viewMostraProdSez &&<InfoModal
                    title={"Articoli all'interno della magazzino/sezione "+ arrayProdSez.nome  +" e sottosezioni"}
                    text={
                        <div className="d-flex flex-column align-items-center " >
                            {arrayProdSez?.prodotti.length > 0 ?
                                <div className="w-100 border border-primary border-3 p-3 rounded-4 m-3" >
                                    <h3>Articoli</h3>

                                    <TabellaProdotti 
                                        array={arrayProdSez.prodotti.filter(prod => prod.bollaUscitaId === null)} 
                                       
                                    />
                                </div>
                                :
                                <>
                                    non ci sono articoli in questa sezione
                                </>
                            }
                            { arrayProdSez?.sezioneFigli.length > 0 ?
                                <div className="border border-primary border-3 p-3 rounded-4 m-3 w-50" >
                                    <h3>SottoSezioni</h3>
                                     {arrayProdSez.sezioneFigli.map((item)=> {
                                        return(
                                            <div>
                                                <p> {item.nome} <button className="btn text-primary" onClick={()=> mostraSottoSezione(item.id)} > <FontAwesomeIcon icon={faEye} /> </button> </p>
                                            </div>
                                        )
                                     })}
                                </div>
                                :
                                <>
                                    non ci sono sottosezzioni in questa sezione
                                </>
                            }
                        </div>
                    }
                    className='modal-xl'
                    canClose
                    handleClose={onCloseMostraProd}
                    onClose={()=>setViewMostraProdSez(false)}
                />}

            </div>

            {!!viewInventario &&
                <InfoModal
                    size='xl'
                    title={'Esporta inventario magazzino'}
                    text={<div className="w-100" >
                        <div className="border_filtra p-3 my-5" >
                            <FiltraSezioni 
                                value={inputsFiltra}
                                onChange={onChangeFiltra}
                            />
                            <PulsantiFiltra
                                filtra={filtraSezioni}
                                azzera={readSez}
                            />
                        </div>

                        <TabellaPaginazione
                            colonne={columns}
                            dati={arraySez}
                            totalePag={pageTot}
                            azzera={azzeraVar}
                            impaginazione={impaginazione}
                            fu={fu}
                            Pulsanti={PulsantiSeleziona}
                        />
         

                    </div>}
                    alternativeFooter={
                        <div>
                            <button className="btn btn-primary m-3" onClick={esportaDati}>
                                esporta
                            </button>
                        </div>
                    }
                    canClose
                    handleClose={()=>setViewInventario(false)}
                />
            }

            {!!viewSezione &&<InfoModal
                size='xl'
                title={'Anagrafica Magazzino/Sezione'}
                text={
                    <div>
                        <div className="d-flex flex-column border border-primary border-3 m-3 p-3 rounded">
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Nome :  
                                </span>
                                {viewSezione?.nome}
                            </span>
                            <span className="col-6">
                            <span className="fw-bold pe-2">
                                Descrizione :  
                            </span>
                                 {viewSezione?.descrizione}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Codice identificativo : 
                                </span>
                                {viewSezione?.codice}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Capienza : 
                                </span>
                                {viewSezione?.capienza}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    unita di misura : 
                                </span>
                                {viewSezione?.UnitaMisura?.nome}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    RFID :
                                </span>
                                {viewSezione?.rfid}
                            </span>

                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Modulare {!!viewSezione?.modulare ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                                </span>
                            </span>

                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Magazzino in opera {!!viewSezione?.opera ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                                </span>
                            </span>

                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Mobile {!!viewSezione?.mobile ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                                </span>
                            </span>
                        </div>

                        <div className="d-flex flex-column border border-primary border-3 m-3 p-3 rounded"> 
                            {viewSezione.padreId !== null ?<span className="col-6">
                                Sezione padre : {viewSezione?.sezionePadre?.nome}
                            </span>
                            :
                            <>
                            <h3 className="mt-4" >Ubicazione impianto : </h3>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        provincia : 
                                    </span>
                                    {viewSezione?.provincia}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        comune : 
                                    </span>
                                    {viewSezione?.comune}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        indirizzo : 
                                    </span>
                                    {viewSezione?.indirizzo}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        civico :
                                    </span>
                                    {viewSezione?.numeroCivico}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        cap : 
                                    </span>
                                    {viewSezione?.cap}
                                </span>
                            </>}
                        </div>
                    </div>
                }

                canClose
                handleClose={()=>setViewSezione(false)}
            />}
        </div>
    )
}