import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket , faBars, faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet } from "react-router-dom";
import Menu from './menu/Menu';
import { useEffect, useState } from 'react';
import logo from '../img/logo_NETOIP.png'
import PulsantiFontSize from './pagine/riutilizabili/PulsantiFontSize';

export default function Base2() {
    
    const [statoMenu ,setStatoMenu] = useState(1)

    const logaut = ()=> {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        window.location.href = '/';
    }
    const username = localStorage.getItem('username')

    const cambiaStatoMenu = () => {
   
        if (statoMenu) {
            setStatoMenu(0);
        }else {
            setStatoMenu(1);
        }
    }



    return(
        <div>
            <div style={{height : '5rem'}} className='border border-3 border-end-0 border-primary border-start-0 border-top-0' >
                <div className='d-flex w-100 justify-content-between h-100 align-items-center' >
                    <div className='p-4 d-flex  align-items-center'>

                        <div className='ms-5 d-block me-5' style={{height : '4rem'}} >
                            <Link to={'/'}>
                                <img className='h-100' src={logo} alt="logo"  />
                            </Link>
                        </div>
                        <div style={{marginLeft : '7rem' , height : '2rem'}} className='d-flex' >
                            {!!statoMenu ? 
                                <button className='btn bg-primary text-white me-5 py-2 h-100' onClick={cambiaStatoMenu} >
                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                </button>
                            :
                            <button className='btn bg-primary text-white me-5 py-2 h-100' onClick={cambiaStatoMenu} >
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </button>
                            }
                        </div>
                        <PulsantiFontSize />
{/*         
                        <div className="btn-group py-1 d-flex align-items-center" role="group" aria-label="Grandezza carattere" style={{height : '2rem'}}>
                            <button 
                                type="button" 
                                className="btn btn-primary font-italic" 
                                onClick={handleFontSizeSmaller}
                            >
                                A
                            </button>

                            <button 
                                type="button" 
                                className="btn btn-primary" 
                                //onClick={handleFontSizeBase}
                            >
                                A
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-primary font-weight-bold" 
                                onClick={handleFontSizeBigger}
                            >
                                A
                            </button>
                        </div> */}
                    </div>

                    <div className='p-5 text-primary' >
                        <span className='fs-5 me-3 w-25'>
                            {username}
                        </span>
                        <button className='btn text-primary' onClick={logaut} >
                            <FontAwesomeIcon icon={faRightToBracket} />
                        </button>
                    </div>
                </div>
            </div>
            <div className='d-flex' >
                {statoMenu ? <div className='w-15 border border-3 border-primary p-3 border-bottom-0 border-top-0 d-inline-block ' style={{height : '91.5vh'}} >
                    <Menu />
                </div> :''}
                <div className={statoMenu === 1 ? 'p-3 d-inline-block w-85 ps-5' : 'p-3 d-inline-block w-100 ps-5'} style={{
                        overflowY: 'scroll' ,
                        height: '80vh'
                }} >
                    <Outlet />
                </div>
         
            </div>
        </div>
    )
}