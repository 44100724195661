import React, { useState, useEffect } from 'react';
import anCliente from '../fetch/anCliente_fetch';
import { useNavigate } from "react-router-dom";
import PopError from '../riutilizabili/PopError';
import RicercaCampiAn from './RicercaCampiAn';
import Tabella from '../riutilizabili/Tabella';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import QuestionModal from '../riutilizabili/QuestionModal';
import anCliente_fetch from '../fetch/anCliente_fetch';
import CreaAnagrafica from './CreaAnagrafica';
import InfoModal from '../riutilizabili/InfoModal';
import ViewAnagrafica from './ViewAnagrafica';
import ConfermaEliminazione from '../riutilizabili/ConfermaEliminazione';
import PaginazioneTitoloPulsanteCrea from '../riutilizabili/PaginazioneTitoloPulsanteCrea';
import TabellaPaginazione from '../riutilizabili/TabellaPaginazione';


export default function ReadAnagrafica() {
    const navigate = useNavigate(); 

    const [inputs, setInputs] = useState({});
    const [datiAn, setDatiAn] = useState([]);
    const [error, setError] = useState([]);
    const [datiCrea , setDatiCrea] = useState({
      liberoProfessionista: 1, azienda: 0, privato: 0, entePubblico: 0
    });
    const [datiModifica , setDatiModifica] = useState({});
    const [datiView , setDatiView] = useState({});
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [viewView , setViewView ] = useState(false);
    const [viewEliminaAn , setViewEliminaAn ] = useState(false);

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)
    const [inputsFiltra , setInputsFiltra] = useState({})



    useEffect( ()=>{
        readAn()
      },[])

    const readAn = async () => {
       await anCliente.get({ page, pageSize})
        .then(response => {
            setDatiAn(response.data.data);
            setPageTot(response.data.pageTot)
            setFu(fu+1)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }else{
                setError(error.response.data)
            }
        })
    }

    const azzeraFiltra = () => {
      readAn()
      setInputs({})
    }

    const columns = [
              {
                id : 1,
                label: 'Denominazione',
                nome: 'viewName',
              },
              {
                id : 4,
                label: 'Email',
                nome: 'email',
              },
              {
                id : 5,
                label: 'Partita Iva',
                nome: 'pIva',
              },
              {
                id : 6,
                label: 'Comune',
                nome: 'comuneResidenza',
              },
              {
                id : 7,
                label: 'Sede legale',
                nome: 'sedeLegale',
              },
              {
                id : 8,
                label: 'Numero di telefono',
                nome: 'numeroTelefono1',
              }
        ]

    const Pulsanti = ({element}) => {

        return(
            <td className='d-flex  justify-content-around p-0 m-0'>
                <button className='btn py-1 my-1 button_fix' >
                    <FontAwesomeIcon className='p-0 m-0 text_bluDue' icon={faEye} onClick={()=>viewAn(element.id)} />
                </button>

                <button className='btn py-1 my-1 button_fix' >
                    <FontAwesomeIcon className='p-0 m-0 text_bluDue' icon={faEdit} onClick={()=>modificaAn(element.id)} />
                </button>

                <button className='btn py-1 my-1 button_fix' >
                    <FontAwesomeIcon className='p-0 m-0 text_bluDue' icon={faTrash} onClick={()=>setViewEliminaAn(element.id)} />
                </button>
            </td>
        )
    }

    const modificaAn = (id) => {
      anCliente.get({id : id})
        .then(response => {
          setDatiModifica(response.data[0])
          setViewModifica(true)
        })
        .catch(error => {
          //console.log( error.response);
          //console.log(error.response.status === 406);
          if (error.response.status === 406) {
              navigate("/login");
          }else{
              setError(error.response.data)
          }
        })
    }

    const viewAn = async (id) => {
      anCliente.get({id : id})
      .then(response => {
        setDatiView(response.data[0])
        setViewView(true)
      })
      .catch(error => {
        if (error.response.status === 406) {
            navigate("/login");
        }else{
            setError(error.response.data)
        }
    })
    }

    const handleChange = (name , value) => {
        //console.log(name , value);
        setInputs(values => ({ ...values, [name]: value }))
        filtra({[name]: value})
    }

    const creaAn = (stato) => {
      if (stato) {
        anCliente_fetch.create(datiCrea)
        .then(response => {
          setError([])
          setDatiCrea({})
          setViewCrea(false)
          readAn()
        })
        .catch(error => {
          setError(error.response.data);
        })
      }else {
        setViewCrea(false)
      }
    }

    const modAn = async (stato) => {
      if (stato) {
        await anCliente_fetch.update(datiModifica)
        .then(response => {
          setError([])
          setViewModifica(false)
          setDatiCrea({})
          readAn()
        })
        .catch(error => {
          setError(error.response.data);
        })
      }else {
        setViewModifica(false)
      }
    }

    const filtra = async(param) => {
      await anCliente.get({...inputs ,page, pageSize, ...param})
      .then(response => {
        //console.log(response.data , 'dido');
        setDatiAn(response.data.data);
        setPageTot(response.data.pageTot)
        setFu(fu+1)
      })
      .catch(error => {
        //console.log(error , ' error dido');
        if (error.response.status === 406) {
          navigate("/login");
        }else{
            setError([error.data])
        }
      })
    }

    const eliminaAn = async (stato) => {
      if (stato) {

        try {
          let response = await anCliente_fetch.elimina({id:viewEliminaAn, page , pageSize})
          setDatiAn(response.data.data);
          setPageTot(response.data.pageTot)
          setFu(fu+1)
          setViewEliminaAn(false)
        } catch (error) {
          if (error.response.status === 406) {
                navigate("/");
              }else{
                  setError({elimina :error.response.data})
              }
        }

        // anCliente_fetch.elimina({id:viewEliminaAn , page , pageSize})
        // .then(response => {
        //   console.log(response.data ,'error dido 2');
        //   setViewEliminaAn(false)
        //   setDatiAn(response.data.data);
        //   setPageTot(response.data.pageTot)
        //   setFu(fu+1)
        // })
        // .catch(error => {
        //   console.log(error.response.data , ' error dido');
        //   if (error.response.status === 406) {
        //     navigate("/");
        //   }else{
        //       setError({elimina :error.response.data})
        //   }
        // })
      }else {
        setViewEliminaAn(false)
        setError({})
      }
    }

    const onChangeTipoAn = (tipoAn) => {
      switch (tipoAn) {
        case 'liberoProfessionista':
          setDatiCrea(values => ({ ...values, liberoProfessionista: 1 }))
          setDatiCrea(values => ({ ...values, azienda: 0 }))
          setDatiCrea(values => ({ ...values, privato: 0 }))
          setDatiCrea(values => ({ ...values, entePubblico: 0 }))
        break;
        case 'azienda':
          console.log(' values azienda');
          
          setDatiCrea(values => ({ ...values, liberoProfessionista: 0 }))
          setDatiCrea(values => ({ ...values, azienda: 1 }))
          setDatiCrea(values => ({ ...values, privato: 0 }))
          setDatiCrea(values => ({ ...values, entePubblico: 0 }))
        break;
        case 'privato':
          setDatiCrea(values => ({ ...values, liberoProfessionista: 0 }))
          setDatiCrea(values => ({ ...values, azienda: 0 }))
          setDatiCrea(values => ({ ...values, privato: 1 }))
          setDatiCrea(values => ({ ...values, entePubblico: 0 }))
        break;
        case 'entePubblico':
          setDatiCrea(values => ({ ...values, liberoProfessionista: 0 }))
          setDatiCrea(values => ({ ...values, azienda: 0 }))
          setDatiCrea(values => ({ ...values, privato: 0 }))
          setDatiCrea(values => ({ ...values, entePubblico: 1 }))
        break;
    }
  }

    const onChangeTipoAnMod = (tipoAn) => {
      switch (tipoAn) {
        case 'liberoProfessionista':
          setDatiModifica(values => ({ ...values, liberoProfessionista: 1 }))
          setDatiModifica(values => ({ ...values, azienda: 0 }))
          setDatiModifica(values => ({ ...values, privato: 0 }))
          setDatiModifica(values => ({ ...values, entePubblico: 0 }))
        break;
        case 'azienda':
          console.log(' values azienda');
          
          setDatiModifica(values => ({ ...values, liberoProfessionista: 0 }))
          setDatiModifica(values => ({ ...values, azienda: 1 }))
          setDatiModifica(values => ({ ...values, privato: 0 }))
          setDatiModifica(values => ({ ...values, entePubblico: 0 }))
        break;
        case 'privato':
          setDatiModifica(values => ({ ...values, liberoProfessionista: 0 }))
          setDatiModifica(values => ({ ...values, azienda: 0 }))
          setDatiModifica(values => ({ ...values, privato: 1 }))
          setDatiModifica(values => ({ ...values, entePubblico: 0 }))
        break;
        case 'entePubblico':
          setDatiModifica(values => ({ ...values, liberoProfessionista: 0 }))
          setDatiModifica(values => ({ ...values, azienda: 0 }))
          setDatiModifica(values => ({ ...values, privato: 0 }))
          setDatiModifica(values => ({ ...values, entePubblico: 1 }))
        break;
    }
    }


  const impaginazione = async (impaginazione)=> {
    setPage(impaginazione.numeroPag)
    setPageSize(impaginazione.pageSize)
    if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
        await filtra({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
        setAzzeraVar(0)
    }
}

    //console.log(datiCrea , ' datiCrea');
    //console.log(datiModifica , ' datiModifica');
    //console.log(datiView, ' datiView');
    return(
        <div className='p-2'>
            <PaginazioneTitoloPulsanteCrea
              titolo={'Anagrafiche'}
              funCrea={() =>setViewCrea(true)}
            />
            <div className='align-items-center d-flex flex-column pt-4' >
                <div className='border_filtra p-3 mx-4 px-4' style={{width: '96%'}}>
                  <RicercaCampiAn onChange={handleChange} values={inputs} filtra={filtra} azzeraFiltra={azzeraFiltra} />
                </div>
                <div className='d-flex justify-content-around w-100' >
                    <div className='m-5 w-100 pe-2'>
                      <TabellaPaginazione 
                        dati={datiAn} 
                        colonne={columns} 
                        totalePag={pageTot} 
                        azzera={azzeraVar} 
                        impaginazione={impaginazione}
                        fu={fu}
                        Pulsanti={Pulsanti} 
                      />
                    </div>
                </div>
                {viewCrea &&
                  <QuestionModal 
                  title='Crea Anagrafica'
                  //text = {<RicercaCampiAn values={datiCrea} onChange={(name , value )=>setDatiCrea(values => ({ ...values, [name]: value })) } errors={error} />}
                  text={
                  <CreaAnagrafica 
                    onChange={(name , value)=> setDatiCrea(values => ({ ...values, [name]: value }))}
                    values={datiCrea}
                    onChangeError={(error)=> setError(Object.keys(error))}
                    error={error}
                    onChangeTipoAn={onChangeTipoAn}
                  />
                }
                  YesLabel = 'Crea'
                  onClose = {creaAn}

                />}
                {viewModifica &&
                  <QuestionModal 
                  title='Modifica Anagrafica'
                  text={
                  <CreaAnagrafica 
                    values={datiModifica}
                    onChange={(name , value)=> setDatiModifica(values => ({ ...values, [name]: value }))}
                    onChangeError={(error)=> setError(Object.keys(error))}
                    error={error}
                    onChangeTipoAn={onChangeTipoAnMod}
                  />
                }
                  YesLabel = 'Modifica'
                  onClose = {modAn}

                />}
                {viewView && <InfoModal
                  size='xl'
                  title={'Anagrafica ' + (datiView?.ragioneSociale ? datiView.ragioneSociale : datiView.nome+' '+datiView.cognome)}
                  handleClose={()=>setViewView(false)}
                  canClose='Chiudi'
                  text={<ViewAnagrafica id={datiView.id} />}
                />}
                <PopError error={error} />

                <ConfermaEliminazione nome='anagrafica' stato={viewEliminaAn} conferma={eliminaAn} error={error} />
            </div>
        </div>
    )
}