import React, { useEffect, useState } from "react";
import login_fetch from "../../fetch/login_fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faMinusSquare, faPlusSquare, faUser, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const icone = {
    'faFile' : faFile ,
    'faUser' : faUser ,
    'faWarehouse' : faWarehouse
}



export default function Menu() {
    const [arrayMenu , setArrayMenu] = useState([]);

    useEffect(()=>{
        creaMenu()
    },[])

    const creaMenu = async ()=> {
        const menu = await login_fetch.mostraMenu()
        if (menu.status === 200) {
            //console.log(menu.data , ' menu');
            setArrayMenu(menu.data)
        }
    }
    const apriMenu = (key) => {
        let tmp = [...arrayMenu];
        tmp[key].aperto = !tmp[key].aperto
        setArrayMenu(tmp)

    }

    return(
        <div className="h-100 navbar-nav-scroll">
           {arrayMenu.length > 0 &&  <div>
                {arrayMenu.map((item , key) => {
                    //console.log(item , ' item');
                    return(
                        <div key={key} >
                            <button
                            className="btn"
                                onClick={()=> apriMenu(key)}
                            >
                                {!!item.aperto ? <FontAwesomeIcon className="me-3" icon={faMinusSquare} />
                                :
                                <FontAwesomeIcon className="me-3" icon={faPlusSquare} />}
                                <span>
                                    <FontAwesomeIcon className="pe-3" icon={icone[item.icona]} />
                                    {item.etichetta}
                                </span>
                            </button>
                            {!!item.aperto && 
                             <ul>
                                {item.VociMenus.map((item2, key2)=> {
                                    let classBase = "border border-2 rounded text-truncate p-2 ";
                                    
                                    switch (key2) {
                                        case 0:
                                        
                                                classBase =classBase+"rounded-bottom-0 border-bottom-0"
                   
                                            break;

                                        case item.VociMenus.length-1:
                                            classBase =classBase+"rounded-top-0"
                                            break;
                                    
                                        default:
                                            classBase =classBase+"rounded-0 border-bottom-0"
                                            break;
                                    }
                                  
                        
                                    // console.log(classBase);
                                    return(
                                        <>
                                            {!!item2.stato &&<li key={key2} className={classBase}>
                                                <Link className="text-decoration-none text-black-50" to={item2.url}>
                                                    {item2.etichetta}
                                                </Link>
                                            </li>}
                                        </>
                                    )
                                })}
                             </ul>
                            }
                        </div>
                    )
                })}
            </div>}


        </div>
    )
}