import http from "./controllerFetch";

class prodSezioneService {
    getAll() {
        return http.get('prod/readProd');
    }

    get( params = {}) {
        return http.get('prod/readProd', { params });
    }

    listaAssProdSezione( params = {}) {
        return http.get('prod/listaAssProdSezione', { params });
    }

    getProdSezioneQtMin( params = {}) {
        return http.get('prod/prodSezioneQtMin', { params });
    }

    getProdBollaUscita( params = {}) {
        return http.get('Prodotto/readProdBollaUscita', { params });
    }

    getSezioniDisp( params = {}) {
        return http.get('prod/readSezioniDisp', { params });
    }
    getSpostaInternoMag( params = {}) {
        return http.get('prod/spostaInternoMag', { params });
    }

    create(data) {
        return http.post('prod/creaProdSezioni', data);
    }

    assProdsSez(data) {
        return http.post('prod/assProdsSez', data);
    }

    spostaProd(data) {
        return http.post('prod/spostaProd', data);
    }

    delete(data) {
        return http.delete('prod/deleteProd', {data});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new prodSezioneService()