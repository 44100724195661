import http from "./controllerFetch";

class permessi {
    getAll() {
        return http.get('Permesso/readPermesso');
    }
    
    get(params = {}) {
        return http.get('Permesso/readPermesso', { params });
    }
    readpermessiGruppo(params = {}) {
        return http.get('Permesso/readpermessiGruppo', { params });
    }

    create(data) {
        return http.post('Permesso/creaPermesso', data);
    }

    update(data) {
        //console.log(data);
        return http.put('Permesso/updatePermesso/' , data);
    }

    getAllAssociazione() {
        return http.get('AssPermessi/readAssPermessi');
    }
    
    getAssociazione(params = {}) {
        return http.get('AssPermessi/readAssPermessi', { params });
    }

    getAssociazioneName(params = {}) {
        return http.get('AssPermessi/readAssociaPermessoName', { params });
    }

    createAssociazione(data) {
        return http.post('AssPermessi/associaPermesso', data);
    }

    updateAssociazione( data) {
        return http.put('AssPermessi/updateAssPermessi', data);
    }

    getAssociazioneGruppo(params = {}) {
        return http.get('AssociaPermessoGruppo/readAssociaPermessoGruppo', { params });
    }

    createAssociazioneGruppo(data) {
        return http.post('AssociaPermessoGruppo/associaPermessoGruppo', data);
    }

    updateAssociazioneGruppo( data) {
        return http.put('AssociaPermessoGruppo/updateAssociaPermessoGruppo', data);
    }
}

export default new permessi();