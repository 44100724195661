import React , { useEffect, useState } from "react"
import classProdSezzione from "../fetch/classProdSezzione";
import CampiProdotti from "./CampiProdotti";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import Tabella from "../riutilizabili/Tabella";
import Switch2 from "../riutilizabili/Switch2";
import ImgTabella from "./ImgTabella";
import create_autocompleteWithService from "../riutilizabili/create_autocompleteWithService";
import anCliente_fetch from "../fetch/anCliente_fetch";
import classSezione from "../fetch/classSezione";
import InputForm from "../riutilizabili/forms/InputForm";
import ViewProdPopUp from "./ViewProdPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import QuestionModal from "../riutilizabili/QuestionModal";
import InfoModal from "../riutilizabili/InfoModal";
import { useNavigate } from "react-router";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";



export default function SelezionaProdSezione({
    onChange ,
    prodIdSelezionati=[]
}) {
    let errors = {}
    const navigate = useNavigate();

    const [inputsFiltra , setinputsFiltra] = useState({}) ;
    const [inputs , setInputs] = useState([]) ;
    const [mostraSeriali , setMostraSeriali] = useState(false) ;

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    useEffect(()=>{
        filtraProd()
    },[])

    // useEffect(()=>{
    //     filtraProd(inputsFiltra)
    // },[inputsFiltra])


    const filtraProd = async (param) => {
        await classProdSezzione.get({pageSize  , page,...param , bollaUscitaId : -1 ,  prodDisponibili : 1})
        .then(response => {
            //console.log(response.data , ' response.data');
            setInputs(response.data.data)
            setPageTot(response.data.pageTot)
            setFu(fu+1)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtraProd({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }

    const selezionaProdotto = (item , stato) => {
        console.log(item , ' item ' , stato);
        onChange(item)
        if (!!stato) {
            setinputsFiltra(values => ({...values, ['sezionePadreId']: null}))
            filtraProd({...inputsFiltra ,  ['sezionePadreId']: null})
        }else {
            setinputsFiltra(values => ({...values, ['sezionePadreId']: item.sezioneId}))
            filtraProd({...inputsFiltra ,  ['sezionePadreId']: item.sezioneId})
        }
    }

    const ProdAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classProdSezzione.getProdBollaUscita({
            nomeCodice: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => (item.nome+ ' '+item.rifInterno)?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.nome+' - '+ item.rifInterno+ ''+ (item.infoFornitore !== null ? ' - '+item.infoFornitore.nome : ' '),
        itemToString: item => item.nome+' - '+ item.rifInterno,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Anagrafica prodotto',
        placeholder: 'inserisci nome o codice prodotto',
        value : inputsFiltra?.nomeProdotto ,
        onChange : ({value, item}) => onChangeProd(item)
    });

    const onChangeProd = (item) => {
        console.log(inputsFiltra , ' inputsFiltra');
        if (item) {
            
        if (item.qtTot === 0) {
            
            setInputs([{
                nome : item.nome ,
                rifInterno : item.rifInterno ,
                infoProd : {
                    codProd : item.codProd,
                    costo : item.costo
                },
                codBarre: item.codBarre,
                infoSezione : {
                    nome : ''
                },
                quantitaResidua : 0 ,
                
                
            }])
        }else {
            setinputsFiltra(values => ({...values, ['nomeProdotto']: item?.nome +' - '+ item?.rifInterno +' - quantità disponibile : '+ item?.qtTot}))
            setinputsFiltra(values => ({...values, ['prodottoId']: item.id}))
            filtraProd({...inputsFiltra ,  ['prodottoId']: item.id})
        }
        // onChangeValue('nomeProdotto' , item?.nome +' - '+ item?.rifInterno +' - quantità disponibile : '+ item?.qtTot) ; 
        // onChangeValue('prodottoId' , item.id)
        } else {
            setinputsFiltra(values => ({...values, ['nomeProdotto']: ""}))
            setinputsFiltra(values => ({...values, ['prodottoId']: ""}))
        }
    }

    console.log(inputs , ' inputs fine');

    const columns =[
            {
                label: 'Immagine',
                nome: 'img',
                altCol : (item)=> {
                    console.log(item , ' itemImmagine');
                    
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                        <ImgTabella prodId={item?.infoProd?.id} />
                        </div>
                    )
                }
            },
            {
                label: 'Nome',
                nome: 'nome',
                altCol : (item)=> {
                    return(
                        <div className="text-center">
                            <ViewProdPopUp
                                icona={item?.nome}
                                prodAnId={item?.id}
                            />
                        </div>
                    )
                }
            },
            {
                label: 'Codice identificativo',
                nome: 'rifInterno',
            },
            {
                label: 'Codice produttore',
                nome: 'codProd',
                altCol : (item)=> {
                    return(
                        <>
                            {item?.infoProd?.codProd}
                        </>
                    )
                }
            },
            {
                label: 'Sezione',
                nome: 'infoSezione',
                altCol : (item)=> {
                    console.log(item , 'item');
                    return(
                        <>
                            {item?.infoSezione?.nome}
                        </>
                    )
                }
            },
            {
                label: 'Codice a barre',
                nome: 'codBarre',
            },
            {
                label: 'Quantità residua',
                nome: 'quantitaResidua',
            },
            {
                label: "Costo d'acquisto",
                nome: 'costo',
                altCol : (item)=> {
                    console.log(item , 'item');
                    return(
                        <>
                            {item?.costo}
                        </>
                    )
                }
            },
            {
                label: "Seriali",
                nome: 'Seriali',
                altCol : (item)=> {
                    console.log(item , 'item');
                    return(
                        <>
                            <button className="btn" onClick={()=>setMostraSeriali(item)} >
                                <FontAwesomeIcon icon={faList} />
                            </button>
                        </>
                    )
                }
            },
    ]
        
    const Pulsanti = ({element}) => {
        console.log(element , ' element');
        return(
            <td>
                {element.quantitaResidua > 0 ? <Switch2
                    stato={prodIdSelezionati.includes(element.id)}
                    number='prod'
                    //onChangeValue={()=>onChange(element)}
                    onChangeValue={()=>selezionaProdotto(element , prodIdSelezionati.includes(element.id))}
                />
                : 
                <span className="text-danger font-weight-bold" >Non disponibile</span>
            }
            </td>
        )
    }

    const Fornitore = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anCliente_fetch.getAll({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item?.ragioneSociale ? item?.ragioneSociale : item.nome+' '+item.cognome,
        itemToString: item => item?.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome?.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Fornitore ',
        value: inputs?.fornitore_nome ,
        onChange : ({value, item}) => {onChangeFiltra('fornitoreId', item?.id) ; onChangeFiltra('fornitore_nome', item?.ragioneSociale ? item?.ragioneSociale : item?.nome+' '+item?.cognome); onChangeFiltra('fornitoreId', item?.id) ;} ,
        placeholder: 'inserisci nome fornitore',
        className: 'W-25'
    });
    const onChangeMagazzinoPrincipale = (item) => {
        if (!!item) {
            let oggetto = [item.id , ...item?.sezioniFigli.map(el => el.id)]
            console.log(item , ' item mag princ',oggetto);
    
            onChangeFiltra('arraySezioneId',oggetto)
            onChangeFiltra('magazzinoPrincipale_nome',item.nome)
            
        }else {
            onChangeFiltra('arraySezioneId',[])
            onChangeFiltra('magazzinoPrincipale_nome','')

        }
    }

    
    const MagazzinoPrincipale = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classSezione.get({
            magazzinoPrincipaleSelect : 1,
            magazzinoPrincipale : 1,
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona Magazzino' ,
        value: inputs?.magazzinoPrincipale_nome ,
        onChange : ({value, item}) => onChangeMagazzinoPrincipale(item) ,
        placeholder: 'inserisci nome magazzino',
        redflag : false ,

    });

    const onChangeFiltra = async (name , value) => {
        setinputsFiltra(values => ({...values, [name]: value})) ; 
        await filtraProd({...inputsFiltra , [name]: value}) 
    }

    return(
        <div>
            <div className="border_filtra p-3 ms-3 me-1 mb-5" >
                {/* <CampiProdotti
                    classiDiv={'d-flex flex-wrap'}
                    value={inputsFiltra}
                    errors={{}}
                    onChange={(name , value )=>{}
                /> */}
                <div className="d-flex flex-wrap">
                    <InputForm 
                        label='Nome'
                        type='text' 
                        placeholder='Inserisci nome'
                        name='nome'
                        value={!!inputsFiltra?.nome ? inputsFiltra.nome : ''}
                        onChange={(name , value) => onChangeFiltra(name , value)}
                        classi=' w-25 m-2'
                        errors={errors}
                    />
                    <InputForm 
                        label='Codice a barre' 
                        type='text' 
                        maxLenght='13'
                        placeholder='Inserisci codice a barre'
                        name='codBarre'
                        value={!!inputsFiltra.codBarre ? inputsFiltra.codBarre : ''}
                        onChange={(name , value) => onChangeFiltra(name , value)}
                        classi='w-25 m-2'
                        errors={errors}
                    />
                    
                    <InputForm 
                        label='Codice produttore '
                        type='text' 
                        placeholder='Inserisci codice produttore'
                        name='codProd'
                        value={!!inputsFiltra.codProd ? inputsFiltra.codProd : ''}
                        onChange={(name , value) =>{ onChangeFiltra(name , value)}}
                        classi='w-25 m-2'
                        errors={errors}
                    /> 
                    
                    {/* <InputForm 
                        label='seriale'
                        type='text' 
                        placeholder='Inserisci codice produttore'
                        name='seriale'
                        value={!!inputsFiltra.seriale ? inputsFiltra.seriale : ''}
                        onChange={(name , value) =>{ onChangeFiltra(name , value)}}
                        classi='w-25 m-2'
                        errors={errors}
                    />  */}
                    
                    <InputForm 
                        label='Codice identificativo '
                        type='text' 
                        placeholder='Inserisci codice identificativo'
                        name='rifInterno'
                        value={!!inputsFiltra.rifInterno ? inputsFiltra.rifInterno : ''}
                        onChange={(name , value) => onChangeFiltra(name , value)}
                        classi='w-25 m-2'
                        errors={errors}
                    /> 
                    {/* <div className='w-25 m-2'>
                        <MagazzinoPrincipale />
                    </div>
                    <div className='w-25 m-2 me-5 ms-3'>
                        <Fornitore />
                    </div> */}
                </div>
                <ProdAutocomplite />

                <PulsantiFiltra
                    azzera={()=>{filtraProd();setinputsFiltra({});}}
                    filtra={()=> filtraProd(inputsFiltra)}
                />
            </div>

            {/* <Tabella
                columns={columns}
                data={inputs}
                Pulsanti={Pulsanti}
            />  */}
            <TabellaPaginazione
                colonne={columns}
                dati={inputs}
                totalePag={pageTot}
                azzera={azzeraVar}
                impaginazione={impaginazione}
                fu={fu}
                Pulsanti={Pulsanti}
            />

            {!!mostraSeriali && 
             <InfoModal
                size='xl'
                classi={' modal-top-5 '}
                title={'Seriali prodotto '+mostraSeriali.nome+' - '+mostraSeriali.rifInterno}
                text={
                    <div>
                        {mostraSeriali.ProdSerialis.length > 0 ?
                        <ul>
                            {mostraSeriali.ProdSerialis.map((item , key) => {
                                return(
                                    <li>
                                        {item.seriale}
                                    </li>
                                )
                            })}
                        </ul>
                        :
                        <div>
                            Nessun seriale presente
                        </div>
                        }
                    </div>
                }
                canClose={true}
                handleClose={()=>setMostraSeriali(false)}
             />
            }

        </div>
    )
    
}