import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classProdSezzione from "../fetch/classProdSezzione";
import { useState } from "react";
import { useNavigate } from "react-router";
import InfoModal from "../riutilizabili/InfoModal";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import { daCentEu } from "../riutilizabili/daEurCent";


export default function ProdottiSezione({
    sezioneId ,
    nomeSezione,
    icona
}) {
    const navigate = useNavigate();

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)
    const [inputsFiltra , setInputsFiltra] = useState({})
    const [prods , setProds] = useState([])

    const [error, setError] = useState([]);
    const [viewSez , setViewSez] = useState(false)

    const searchProdSezione = async (params) => {
        try {
            const prodsTmp = await classProdSezzione.get({sezioneId : sezioneId ,page , pageSize , ...params})
            setProds(prodsTmp.data.data)
            setPageTot(prodsTmp.data.pageTot)
            setFu(fu+1)
        } catch (error) {
            console.log(error , ' error');
            
            if (error.response.status === 406) {
                navigate("/");
              }else{
                  setError({elimina :error.response.data})
              }
        }
    }

    const columns = [
        {
        label: 'Nome',
        nome: 'nome',
        altCol: (item)=> {
            return(
                <>
                    {item?.infoProd?.nome}
                </>
            )
        }
        },
        {
        label: 'Codice a barre',
        nome: 'codBarre',
        },
        {
            label: 'Unità di misura',
            nome: 'umId',
            altCol: (item)=> {
                console.log(item , ' item');
                
                return item?.infoUnitaMisura?.nome
            }
            },
        {
        label: 'Quantità iniziale',
        nome: 'quantita',
        },
        {
        label: 'Quantità residua',
        nome: 'quantitaResidua',
        },
        {
        label: 'Prezzo',
        nome: 'prezzoUnitarioCent',
         altCol: (item)=> {
            return(
                <>
                    {daCentEu(item.prezzoUnitarioCent)}
                </>
            )
        }
        },
        {
        label: 'Sezione',
        nome: 'infoSezione',
         altCol: (item)=> {
            let percorso =''
            if (!!item.infoSezione) {
                percorso = item?.infoSezione?.nome
                let og = item.infoSezione
                while (og.padreId !== null) {
                    percorso = og.sezionePadre.nome+ ' / '+percorso
                    og = og.sezionePadre
                }
            }
            return(
                <>
                    {!!item.infoSezione ? percorso : 'Sezione non assegnata'}
                </>
            )
        }
        },
    ]

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await searchProdSezione({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag })
            setAzzeraVar(0)
        }
    }

    const apri= ()=> {
        classProdSezzione.get({sezioneId : sezioneId ,page , pageSize })
        .then(response => {
            setProds(response.data.data)
            setPageTot(response.data.pageTot)
            setFu(fu+1)
        })
        setViewSez(true)
    }

    console.log(prods , ' prods');
    

    return(
        <>
            {!viewSez ?
            <button className="btn p-0 m-0" onClick={apri} >
                {icona}
            </button>
            :
            <InfoModal
                size='xl'
                title={"Prodotti all'interno della sezione "+nomeSezione}
                text={
                    <div>
                        <TabellaPaginazione 
                            colonne={columns} 
                            dati={prods} 
                            totalePag={pageTot}
                            azzera={azzeraVar} 
                            impaginazione={impaginazione} 
                            fu={fu} 
                        />
                    </div>
                }
                canClose
                handleClose={()=>setViewSez(false)}
            />}
        
        </>
    )
}