import React from "react"
import InputForm from "../riutilizabili/forms/InputForm";
import sezioneService from '../fetch/classSezione'
import create_autocompleteWithService from "../riutilizabili/autocomplite/create_autocompleteWithService";
import TextAreaForm from "../riutilizabili/forms/TextAreaForm";
import Switch from "../riutilizabili/forms/Switch";
import AutocompliteCopertura from "../riutilizabili/autocomplite/autocompliteCopertura";
import SwitchOr from "../riutilizabili/forms/SwitchOr";

export default function CampiSezioni({classiDiv , values , onChange , errors}) {

    console.log(values , ' values');

    const SezioneAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getAll({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona sezione/magazzino padre' ,
        value: values.nomeSezionePadre ,
        //onChange : ({value, item}) => //console.log({value, item}, ' gino') ,
        onChange : ({value, item}) =>{onChange('padreId' ,  item?.id ? item.id : ''); onChange('nomeSezionePadre' , item?.nome ? item.nome : '')} ,
        placeholder: 'inserisci nome sezione/magazzino padre',
        redflag : !!errors?.padreId
        
    });

    const UnMisuraAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getUnMisura({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona unità di misura della capienza' ,
        value: values.nomeUnitaMisura ,
        onChange : ({value, item}) => {onChange('umId' , item?.id ? item.id : ''); onChange('nomeUnitaMisura' , item?.nome ? item.nome : '')},
        placeholder: 'inserisci unità di misura della capienza',
        redflag : !!errors?.umId,
        className:' col-4 '
    });

    return(
        <div 
       // className='d-flex  justify-content-around flex-wrap ' 
        >
            <div className={classiDiv}>
                <div className="w-100">
                    <SwitchOr
                        label={'Sezione di un magazzino esistente'}
                        number={'sezione'}
                        stato={values?.sezione}
                        onChangeValue={(stato, nome) => onChange(nome , stato)}
                        classe='d-flex p-0 m-0 ms-3'
                        width="27%"
                    />
                </div>
                <InputForm
                    label='Nome' 
                    type='text' 
                    placeholder='Inserisci nome'
                    name='nome'
                    value={values.nome ? values.nome : ''}
                    onChange={(name , value) => onChange(name , value)}
                    classi='w-25'
                    errors={errors}
                />
                {!values?.sezione && <InputForm
                    label='codice identificativo' 
                    type='text' 
                    placeholder='Inserisci codice identificativo'
                    name='codice'
                    value={values.codice ? values.codice : ''}
                    onChange={(name , value) => onChange(name , value)}
                    classi='w-25'
                    errors={errors}
                />}
                
                <div className="w-100">
                    <TextAreaForm
                        label='Descrizione' 
                        name='descrizione'
                        value={values.descrizione ? values.descrizione : ''}
                        onChange={(name , value) => onChange(name , value)}
                        classi='w-75'
                        errors={errors}
                    />
                </div>
                <div className="d-flex flex-wrap align-items-center" >
                <InputForm
                    label='Capienza' 
                    type='number' 
                    placeholder='Inserisci capienza'
                    name='capienza'
                    value={values.capienza ? values.capienza : ''}
                    onChange={(name , value) => onChange(name , value)}
                    classi='col-2'
                    errors={errors}
                />
                <div className="col-6 mx-2" >
                    <UnMisuraAutocomplite />
                </div>       
                <InputForm
                    label='RFID' 
                    type='text' 
                    placeholder='Inserisci RFID'
                    name='rfid'
                    value={values.rfid ? values.rfid : ''}
                    onChange={(name , value) => onChange(name , value)}
                    classi='col-3'
                    errors={errors}
                />
                </div>
                <div className="d-flex flex-wrap align-items-center" >
                    <div className="text-center ms-3" >
                        <Switch
                            label='Modulare'
                            stato={parseInt(values.modulare)}
                            onChangeValue={(value , name) => onChange(name , value+'')}
                            number='modulare'
                            width='100%'
                            classe={'m-0 p-0'}
                        />  
                        <span className="fa-sm fst-italic">Può contenere sezioni</span>
                    </div>
                    <div className="text-center ms-3" >
                        <Switch
                            label='Magazzino in opera'
                            stato={parseInt(values.opera)}
                            onChangeValue={(value , name) => onChange(name , value+'')}
                            number='opera'
                            width='100%'
                            classe={'m-0 p-0'}
                        />   
                        <span className="fa-sm fst-italic">Se è un cantiere</span>
                    </div>
                    <div className="text-center ms-3" >         
                        <Switch
                            label='Mobile'
                            stato={parseInt(values.mobile)}
                            onChangeValue={(value , name) => onChange(name , value+'')}
                            number='mobile'
                            width='100%'
                            classe={'m-0 p-0'}
                        />   
                        <span className="fa-sm fst-italic">In carico a  un fornitore</span>
                    </div>

                    {/* <InputForm
                        label='Portata max' 
                        type='number' 
                        placeholder='Inserisci portata max'
                        name='portataMax'
                        value={values.portataMax ? values.portataMax : ''}
                        onChange={(name , value) => onChange(name , value)}
                        classi='w-15'
                        errors={errors}
                        /> */}
                    {values?.sezione ? <div className="ms-5 w-25">
                        <SezioneAutocomplite />
                    </div>
                    : 
                    <div className="mt-4 ms-2">
                        <h3>Ubicazione Magazzino/Impianto definitivo</h3>
                        <div className="d-flex flex-wrap">
                            <div className='col-3' >
                                <InputForm  
                                    label='Provincia'
                                    type = 'text' 
                                    placeholder = 'Inserisci provincia'
                                    name = 'provincia'
                                    value = {values?.provincia}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {60}
                                />
                            </div>
                            <div className='col-3'>
                                <InputForm  
                                    label='comune'
                                    type = 'text' 
                                    placeholder = 'Inserisci comune'
                                    name = 'comune'
                                    value = {values?.comune}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {60}
                                />
                            </div>
                            <div className='col-5'>
                                <InputForm  
                                    label='indirizzo'
                                    type = 'text' 
                                    placeholder = 'Inserisci indirizzo'
                                    name = 'indirizzo'
                                    value = {values?.indirizzo}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                />
                            </div>
                            <div className='col-2'>
                                <InputForm  
                                    label='civico'
                                    type = 'text' 
                                    placeholder = 'Inserisci civico'
                                    name = 'numeroCivico'
                                    value = {values?.numeroCivico}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {7}
                                />
                            </div>
                            <div className='col-2'>
                                <InputForm  
                                    label='cap'
                                    type = 'text' 
                                    placeholder = 'Inserisci cap'
                                    name = 'cap'
                                    value = {values?.cap}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {5}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        </div>
    )
}