import { faEdit, faEye, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ViewAnagrafica from '../anagrafica/ViewAnagrafica';
import user_fetch from '../fetch/user_fetch';
import InfoModal from '../riutilizabili/InfoModal';
import PulsantiFiltra from '../riutilizabili/PulsantiFiltra';
import QuestionModal from '../riutilizabili/QuestionModal';
import Tabella from '../riutilizabili/Tabella';
import CampiUsers from './CampiUsers';
import TabellaPaginazione from '../riutilizabili/TabellaPaginazione';
import PaginazioneTitoloPulsanteCrea from '../riutilizabili/PaginazioneTitoloPulsanteCrea';
import PermessiUser from './PermessiUser';

export default function ReadUsers() { 
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({});
    const [datiUtenti, setDatiUtenti] = useState([]);
    const [modificaDatiUtente, setModificaDatiUtente] = useState([]);
    const [modificaViewUtente, setModificaViewUtente] = useState(false);
    const [creaDatiUtente, setCreaDatiUtente] = useState([]);
    const [creaViewUtente, setCreaViewUtente] = useState(false);
    const [error, setError] = useState([]);
    const [viewAn , setViewAn ] = useState(false);

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)
    const [inputsFiltra , setInputsFiltra] = useState({})

    
    useEffect( ()=>{
        readUsers()
      },[])

      useEffect(()=>{verCampiOb()},[creaDatiUtente])

      const campiOb = [
        'username',
        'password_hash',
        'email'
      ]

      const verCampiOb = () => {
        campiOb.forEach(element => {
          if (!creaDatiUtente[element]) {
            setError(values => ({ ...values, [element]: 'Campo obbligatorio' }))
          } else {
            let tpm = {...error}
            if (!!tpm[element]) {
                delete tpm[element] 
                setError(tpm)
            }
          }
        });
      }

      const readUsers = () => {
        user_fetch.get({ page, pageSize})
        .then(response => {
          setDatiUtenti(response.data.data);
          setPageTot(response.data.pageTot)
          setFu(fu+1)
          // let tmp = [...response.data]
          // for (let index = 0; index < tmp.length; index++) {
          //   const element = tmp[index];
          //   //console.log(element.status , ' response.data');
          //   if (element.status === 1) {
          //     element.status = <div className='d-flex justify-content-center' >
          //         <div 
          //           className="border rounded-circle" 
          //           style={{width: '25px', height: '25px', backgroundColor: 'green', marginLeft: '56px'}}
          //         >
          //           &nbsp;
          //         </div>
          //     </div>
          //   }else {
          //     element.status = <div className='d-flex justify-content-center' >
          //         <div 
          //           className="border rounded-circle" 
          //           style={{width: '25px', height: '25px', backgroundColor: 'red', marginLeft: '56px'}}
          //         >
          //           &nbsp;
          //         </div>
          //     </div>

          //   }
            
          // }
          //setDatiUtenti(tmp);
        })
        .catch(error => {
          if (error.response.status === 406) {
              navigate("/login");
          }
      })
        
      }
      const azzeraFiltra = async () => {
        filtra({pageSize , page : 1})
        setInputs({})
      }

      const columns =[
              {
                id : 1, 
                label: 'Username',
                nome: 'username',
              },
              {
                id : 2, 
                label: 'Email',
                nome: 'email',
              },
              {
                id : 4, 
                label: 'Stato',
                nome: 'status',
                altCol: (item)=> {

                  return(
                    <div className='d-flex justify-content-center' >
                        {item.status === 1 ?
                          <div 
                            className="border rounded-circle" 
                            style={{width: '25px', height: '25px', backgroundColor: 'green', marginLeft: '56px'}}
                          >
                            &nbsp;
                          </div>
                          :
                          <div 
                            className="border rounded-circle" 
                            style={{width: '25px', height: '25px', backgroundColor: 'red', marginLeft: '56px'}}
                          >
                            &nbsp;
                          </div>
                        }
                    </div>
                )
                }
              }
        ]

      const Pulsanti = ({element}) => {
        return(
            <td className='p-0 m-0'>
                {/* <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faEye} onClick={()=> setViewAn(id.id.original.anagrId)} />
                </button> */}

                <PermessiUser userId={element.id} utente={element.username} stato={fu}/>

                <button className='btn p-0 m-0 button_fix' >
                    <FontAwesomeIcon className='p-0 m-0 text_bluDue' icon={faEdit} onClick={()=> modUser(element.id)} />
                </button>
            </td>
        )
      }

      const modUser = async (id) => {
        user_fetch.get({id:id})
        .then(response => {
          setModificaDatiUtente(response.data[0]);
          setModificaViewUtente(true)
        })
        .catch(error => {
          if (error.response.status === 406) {
              navigate("/login");;
          }else{
              setError(error.response.data)
          }
      })

      }

      const handleChangeModifica = (name , value) =>{
        //console.log(name , value);
        setModificaDatiUtente(values => ({ ...values, [name]: value }))
      }

      const saveModUtente = async (stato) => {
        if (stato) {
            let tmp = {...modificaDatiUtente}
            delete tmp.dataLastmod
            delete tmp.dataUltimoLogin
            await user_fetch.update(tmp)
            .then(response => {
                //console.log(response , ' response');
                setModificaViewUtente(false)
                readUsers()
            })
            .catch(error => {
                //console.log(error , ' error');
            })
        }else {
            setModificaViewUtente(false)
        }
      }

      const filtra = async (param) => {
        await user_fetch.get({ page, pageSize , ...param})
        .then(response => {
          //setDatiUtenti(respomse.data);
          setDatiUtenti(response.data.data);
          setPageTot(response.data.pageTot)
          setFu(fu+1)
        })
        .catch(error=> {
          if (error.response.status === 406) {
              navigate("/login");;
          }else {
              setError(error.response.data)

          }
        })
      }

      const creaUser = async (stato) => {
        if (stato) {
          await user_fetch.create(creaDatiUtente)
          .then(response => {
            setCreaDatiUtente({})
            setCreaViewUtente(false)
            readUsers()
          })
          .catch(error=> {
            if (error.response.status === 406) {
                navigate("/login");;
            }else {
                setError(error.response.data)
            }
          })
        }else {
          setCreaViewUtente(false)
        }
      }

      const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtra({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }
      
      //console.log(creaDatiUtente , ' creaDatiUtente');
      return(
        <div className='p-2'>
          <PaginazioneTitoloPulsanteCrea
            titolo={'Utenti'}
            funCrea={() =>setCreaViewUtente(true)}
          />

          <div className='align-items-center d-flex flex-column pt-4' >
          <div className='border_filtra p-3 mx-4 px-4' style={{width: '96%'}}>
            <CampiUsers 
              value ={inputs}
              onChange={(name , value) => { setInputs(values => ({ ...values, [name]: value })); filtra({...inputsFiltra, [name]: value})}}
              errors={[]}
              filtra = {true}
              classiDiv={'d-flex'}
            />
              <PulsantiFiltra
                  filtra={filtra}
                  azzera={azzeraFiltra}
              />
          </div>
          <div className='mx-5 p-5 w-100'>
            <TabellaPaginazione
              dati={datiUtenti} 
              colonne={columns} 
              totalePag={pageTot} 
              azzera={azzeraVar} 
              impaginazione={impaginazione}
              fu={fu}
              Pulsanti={Pulsanti} 
            />
          </div>
          </div>
            <div className='mt-5'>
            
                {creaViewUtente &&
                  <QuestionModal
                  title='Crea Utente'
                  text={
                    <div>
                      <CampiUsers 
                        value={creaDatiUtente} 
                        onChange={(name ,value) => setCreaDatiUtente(values => ({ ...values, [name]: value }))}
                        errors={error} 
                        classiDiv={' d-flex flex-wrap '}
                        onChangeError={error =>setError(error) }
                      />
                    </div>
                  }
                  onClose={creaUser}
                  />
                  
                }
            
                {/* <Tabella data={datiUtenti} columns={columns} Pulsanti = {Pulsanti}/> */}

                {modificaViewUtente &&<QuestionModal 
                    title={"Modifica utente "+modificaDatiUtente?.username }
                    text={
                    <CampiUsers 
                        classiDiv={'d-flex'}
                        value={modificaDatiUtente} 
                        onChange={handleChangeModifica}
                        //errors={error} 
                        errors={{}}
                     />
                    }
                    onClose={saveModUtente}
                />}

                {viewAn &&
                    <InfoModal
                        title={'Anagrafica'}
                        text={<ViewAnagrafica id={viewAn} />}
                        handleClose={() => setViewAn(false)}
                        canClose={true}
                    />
                }

            </div>
        </div>
      )
}