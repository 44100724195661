import React, {useEffect, useState } from "react";
import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CampiBolla from "./CampiBolla";
import QuestionModal from "../riutilizabili/QuestionModal";
import { daEuCent } from "../riutilizabili/daEurCent";
import bolla_fetch from "../fetch/bolla_fetch";
import TabellaBolle from "./sezioni/TabellaBolle";
import FiltraBolla from "./FiltraBolla";
import InfoModal from "../riutilizabili/InfoModal";
import ReadCampiBolla from "./sezioni/ReadCampiBolla";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";
import { format } from "date-fns";





export default function ViewBolla(onChangeCreaV) {
    const [inputs , setInputs] = useState({dataFattura : format(new Date(), 'yyyy-MM-dd')});
    const [inputsModifica , setInputsModifica] = useState({});
    const [inputsFiltra , setInputsFiltra] = useState({orderMoreRecente : 'DESC'});
    const [viewBolla , setViewBolla] = useState(false);
    const [bolla , setBolla] = useState({});
    const [errors , setErrors] = useState({});
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [arrayBolla , setArrayBolla] = useState([]);
    const [statoAvanti , setStatoAvanti] = useState(false);
    const [campiObSegnale , setCampiObSegnale] = useState({
        'indirizzo': 'campo obbligatorio',
        'provincia': 'campo obbligatorio',
        'codiceFiscale': 'campo obbligatorio',
        'numeroCivico': 'campo obbligatorio',
        'numero': 'campo obbligatorio',
        'dataFattura': 'campo obbligatorio',
    });

    
    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    useEffect(()=> {
        readBolle() 
    },[])

    useEffect(()=> {
        if (!!onChangeCreaV.onChangeCrea) {
            setViewCrea(true) 
        }
    },[!!onChangeCreaV])

    useEffect(()=> {
        abilitaAvanti()
    },[inputs])

    const campiOb = [
        'numero',
        'dataFattura',

    ]

    const abilitaAvanti = () => {
        console.log(campiOb , inputs);
        let error = funzioniRiccorrenti.validazioneCampi(campiOb , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            setCampiObSegnale(error)
            setStatoAvanti(false)
        }else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    const readBolle = async () => {
        const bolle = await bolla_fetch.get({orderMoreRecente : 'DESC',pageSize , page ,});
        //console.log(bolle , ' bolle');
        setInputsFiltra({})
        setArrayBolla(bolle.data.data);
        setPageTot(bolle.data.pageTot)
        setFu(fu+1)
    }

    const readFiltraBolle = async (param) => {
        const bolle = await bolla_fetch.get({...inputsFiltra,pageSize , page , ...param});
        console.log(bolle , ' bolle');
        setArrayBolla(bolle.data.data);
        setPageTot(bolle.data.pageTot)
        setFu(fu+1)
    }

    const onChangeCreaBolla = async(stato) => {
        if (!!stato) {
            const bolla = await bolla_fetch.creaBollaEntrata(inputs);
            if (bolla.status === 200) {
                setInputs({})
                setViewCrea(false)
                setErrors({})                
            }
           // console.log(bolla , ' bolla creata');
        } else {
            setViewCrea(false)
            setErrors({})  
        }
        readBolle()
    }

    const onChangeModificaBolla = async(stato) => {
        if (!!stato) {
            try {
                await bolla_fetch.modBollaEntrata(inputsModifica);
                setViewModifica(false)
                setErrors({})
                // console.log(bolla , ' bolla creata');
            } catch (error) {
                console.log(error.response.data, ' error.response.data.errors');
                setErrors(error.response.data)
            }
            
        } else {
            setViewModifica(false)
            setErrors({})
        }
        readBolle()
    }

    const onChangeValue= (nome , valore) => {
        console.log(nome);
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputs(values => ({ ...values, [nome]: valore }))
        }
    }

    const onChangeValueMod= (nome , valore) => {
      //  console.log(nome , valore , ' onChangeValueMod', inputsModifica);
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsModifica(values => ({ ...values, [nome]: cent }))
            setInputsModifica(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsModifica(values => ({ ...values, [nome]: valore }))
        }
    }

    const onChangeValueFiltra= (nome , valore) => {
        console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsFiltra(values => ({ ...values, [nome]: cent }))
            setInputsFiltra(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsFiltra(values => ({ ...values, [nome]: valore }))
        }
        readFiltraBolle({[nome]: valore})
    }

    const recuperaDatiModBolla = (dati) => {
        let oggetto = {}
       // console.log(dati , 'dati');
        Object.keys(dati).forEach(element => {
            //console.log();
            switch (element) {
                case 'infoFornitore':
                    Object.keys(dati.infoFornitore).forEach(element2 => {
                        oggetto['an'+element2.charAt(0).toUpperCase()+element2.slice(1)] = dati.infoFornitore[element2]
                        console.log(element2 , ' element2');
                        
                        if (element2 === 'viewName') {
                            oggetto.anNominazioneFornitore = dati.infoFornitore[element2]
                        }
                    });
                break;

                case 'dataOraPartenza' :
                    if (!!dati[element]) {
                        const formattedDate = format(dati[element], "yyyy-MM-dd'T'HH:mm")
                        oggetto[element] = formattedDate
                    }
                break;
            
                case 'prods':
                    if (dati.prods.length) {
                        oggetto.prods= []
                        dati.prods.forEach(element2 => {
                            if (element2.stato === 1) {
                                Object.keys(element2.infoProd).forEach(element3 => {    
                                    if (element3 !== 'id' && element3 !== 'costo' && element3 !== 'iva' && element3 !== 'costoCent') {
                                        element2[element3] = element2.infoProd[element3]
                                    }
                                })
                                element2.seriali = element2.ProdSerialis.map((item)=>{return item.seriale}).join(';')
                                oggetto.prods.push(element2)
                            }
                        })

                    }
                break;
            
            
                default:
                    oggetto[element] = dati[element]
                break;
            }
        });
       console.log(oggetto , 'oggetto');
        setInputsModifica(oggetto)
        setViewModifica(true)
    }

    const Pulsanti = ({element}) => {

        return(
            <td className=" py-0 my-0">
                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0' onClick={()=>setViewBolla(element)} icon={faEye} />
                </button>
  
                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0' icon={faEdit} onClick={()=>recuperaDatiModBolla(element)} />
                </button>
            </td>
        )
    }

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await readFiltraBolle({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }


    return(
        <div className="pt-2">
            <PaginazioneTitoloPulsanteCrea titolo={'Ddt in entrata'} funCrea={()=> setViewCrea(true)} />

            <div className="p-4 ps-5">
                <div className='border_filtra p-3 mx-4 px-4' >
                    <FiltraBolla inputs={inputsFiltra} onChangeValue={onChangeValueFiltra} />
                    <div className="ps-2">
                        <PulsantiFiltra azzera={readBolle} filtra={readFiltraBolle} />
                    </div>
                </div>

                <div className=' w-100 m-5 mx-0 px-3 pe-4 pt-2'>
                    <TabellaBolle
                        array={arrayBolla}
                        azzeraVar={azzeraVar}
                        pageTot={pageTot}
                        Pulsanti={Pulsanti}
                        impaginazione={impaginazione}
                        FiltraBolla={inputsFiltra}
                        fu={fu}
                        onChangeFiltraBolla={(nome , valore)=>{setInputsFiltra(values => ({ ...values, [nome]: valore })); readFiltraBolle({[nome]: valore})}}
                    />
                </div>
            </div>

            {viewCrea &&<QuestionModal
                title={<div className="ms-4" >Crea ddt in entrata </div>}
                text={
                    <CampiBolla
                        inputs={inputs} 
                        errors={{...errors , ...campiObSegnale}}
                        onChange={onChangeValue}
                        entrata
                        />
                   }
                YesLabel='Salva'
                yesDisabled={!statoAvanti}
                onClose={onChangeCreaBolla}
                classi=' modal-xxl '
            />}

            {viewModifica &&<QuestionModal
                title={<div className="ms-3" >Modifica ddt in entrata{' N.'+inputsModifica.id + ' del '+ format(inputsModifica.dataFattura, 'dd/MM/yyyy') }</div>}
                text={
                    <CampiBolla
                        inputs={inputsModifica} 
                        errors={{...errors }}
                        onChange={onChangeValueMod}
                        entrata
                        />
                   }
                YesLabel='Salva'
                onClose={onChangeModificaBolla}
                classi=' modal-xxl '
            />}

            {!!viewBolla && 
            <InfoModal
                size='xl'
                title={'Ddt n° ' +viewBolla.numero}
                text={
                    <ReadCampiBolla inputs={viewBolla} infoAddView={false}/>
                }
                canClose
                handleClose={()=>setViewBolla(false)}
                className = 'modal-lg'
            />}
        </div>
    )
}