import React from "react"
import Modal from 'react-bootstrap/Modal'

/**
 * messaggio mediante un modal, domanda NO|SI torna boolean
 * props: 
*   string title
 *   string text
 *   function  onClose gestisci chiusura riceve boolean
 */
function QuestionModal({ 
    AltFooter, 
    title, 
    yesDisabled, 
    text, 
    children, 
    onClose, 
    YesLabel = "Conferma", 
    NoLabel = "Annulla", 
    classi,
    ...ps 
}) {
    
    return (
        <Modal className={'modal-xl '+classi} show onHide={() => onClose(0)} animation={false} {...ps}>
            <Modal.Header className="bg-primary text-white" closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >{text}{children}</Modal.Body>

            {AltFooter ? AltFooter : (
                <Modal.Footer className="bg-primary text-white" >
                    <button type="button" onClick={() => onClose(false)} className="btn bg-white text-primary">{NoLabel}</button>
                    <button disabled={yesDisabled} type="button" onClick={() => onClose(true)} className="btn bg-white text-primary">{YesLabel}</button>
                </Modal.Footer>
            )}
        </Modal>
    );
}

export default QuestionModal; 