import { faEdit, faEye, faFile, faFileInvoiceDollar, faFileLines, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState } from "react";
import PulsantiFiltra from "../../riutilizabili/PulsantiFiltra.js";
import bolla_fetch from "../../fetch/bolla_fetch.js";
import { daEuCent } from "../../riutilizabili/daEurCent.js";
import QuestionModal from "../../riutilizabili/QuestionModal.js";

import FiltraBollaUscita from './FiltraBollaUscita.js'

import TabellaBolleUscita from './TabellaBolleUscita.js'
import CampiBollaUscita from './CampiBollaUscita.js'
import funzioniRiccorrenti from "../../riutilizabili/funzioniRiccorrenti.js";
import InfoModal from "../../riutilizabili/InfoModal.js";
import ReadCampiBolla from "../sezioni/ReadCampiBolla.js";
import PaginazioneTitoloPulsanteCrea from "../../riutilizabili/PaginazioneTitoloPulsanteCrea.js";
import file_fetch from "../../fetch/file_fetch.js";
import { format } from "date-fns";
import Attendere from "../../riutilizabili/Attendere.js";

export default function ViewBollaUscita(onChangeCreaV) {
    const [inputs , setInputs] = useState({dataFattura : format(new Date(), 'yyyy-MM-dd') , dataOraPartenza :format(new Date(), "yyyy-MM-dd'T'HH:mm")});
    const [inputsModifica , setInputsModifica] = useState({});
    const [inputsFiltra , setInputsFiltra] = useState({orderMoreRecente : 'DESC'});
    const [viewBolla , setViewBolla] = useState(false);
    const [errors , setErrors] = useState({});
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [arrayBolla , setArrayBolla] = useState([]);
    const [statoAvanti , setStatoAvanti] = useState(false);
    const [statoAvantiMod , setStatoAvantiMod] = useState(false);
    const [campiObSegnale , setCampiObSegnale] = useState({});

    const [viewAttendere , setViewAttendere] = useState(false);
    
    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    console.log(onChangeCreaV, ' onChangeCreaV');

    useEffect(()=>{
        readBolle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(()=> {
        if (!!onChangeCreaV.onChangeCrea) {
            setViewCrea(true) 
        }
    },[!!onChangeCreaV])

    const campiObbligatori = [
        'dataFattura',
    ]

    const onChangeStatoAvanti = ()=> {
        // const tpm = funzioniRiccorrenti.pulisciCampiAn(inputs)
        // setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , tpm , campiObSegnale , setCampiObSegnale))
        const error = funzioniRiccorrenti.validazioneCampi(campiObbligatori , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            if (error['anIndirizzo']) {
                error['indirizzo'] = error['anIndirizzo']
            }
            if (error['anProvincia']) {
                error['provincia'] = error['anProvincia']
            }
            if (error['anCodiceFiscale']) {
                error['codiceFiscale'] = error['anCodiceFiscale']
            }
            setCampiObSegnale(error)
            setStatoAvanti(false)
        }else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    const onChangeStatoAvantiMod = ()=> {
        // const tpm = funzioniRiccorrenti.pulisciCampiAn(inputs)
        // setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , tpm , campiObSegnale , setCampiObSegnale))
        const error = funzioniRiccorrenti.validazioneCampi(campiObbligatori , inputsModifica);
        console.log(error);
        if (Object.keys(error).length > 0) {
            if (error['anIndirizzo']) {
                error['indirizzo'] = error['anIndirizzo']
            }
            if (error['anProvincia']) {
                error['provincia'] = error['anProvincia']
            }
            if (error['anCodiceFiscale']) {
                error['codiceFiscale'] = error['anCodiceFiscale']
            }
            setCampiObSegnale(error)
            setStatoAvanti(false)
        }else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    useEffect(()=>{
        onChangeStatoAvanti()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inputs])

    useEffect(()=>{
        onChangeStatoAvantiMod()
        // setStatoAvantiMod(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , funzioniRiccorrenti.pulisciCampiAn(inputsModifica) , campiObSegnale , setCampiObSegnale))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inputsModifica])

    const readBolle = async () => {
        const array = await bolla_fetch.getBollaUscita({page, pageSize ,orderMoreRecente : 'DESC'});
        setInputsFiltra({})
        setArrayBolla(array.data.data);
        setPageTot(array.data.pageTot)
        setFu(fu+1)
    }

    const filtra = async (param) => {
        const array = await bolla_fetch.getBollaUscita({...inputsFiltra,page, pageSize, ...param});
        setArrayBolla(array.data.data);
        setPageTot(array.data.pageTot)
        setFu(fu+1)
    }

    
    const onChangeValue = (nome , valore) => {
        //console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputs(values => ({ ...values, [nome]: valore }))
        }
        setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , funzioniRiccorrenti.pulisciCampiAn(inputs) , campiObSegnale , setCampiObSegnale))
    }
    
    const onChangeModifica = (nome , valore) => {
        //console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsModifica(values => ({ ...values, [nome]: cent }))
            setInputsModifica(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsModifica(values => ({ ...values, [nome]: valore }))
        }

        setStatoAvantiMod(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , funzioniRiccorrenti.pulisciCampiAn(inputsModifica) , campiObSegnale , setCampiObSegnale))
    }
    
    const onChangeValueFiltra= (nome , valore) => {
        //console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsFiltra(values => ({ ...values, [nome]: cent }))
            setInputsFiltra(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsFiltra(values => ({ ...values, [nome]: valore }))
        }
        filtra({ [nome]: valore })
    }

    const scaricaPdf = async (bollaId) => {
        await file_fetch.scaricaAllegato({bollaId})
    }

    const scaricaPdfNoPrezzi = async (bollaId) => {
        await file_fetch.scaricaAllegatoNoPrezzi({bollaId})
    }
    
    const Pulsanti = ({element}) => {
        //console.log(id.id.original ,' id.id.original');
        return(
            <td className=" py-0 my-0">
                <button className='btn py-0 my-0 button_fix' title="Visualizza bolla" >
                    <FontAwesomeIcon className=' py-0 my-0' onClick={()=>setViewBolla(element)} icon={faEye} />
                </button>
  
                <button className='btn py-0 my-0 button_fix' title="Modifica bolla" >
                    <FontAwesomeIcon className=' py-0 my-0' icon={faEdit} onClick={()=>recuperaDatiModBolla(element)} />
                </button>
  
                <button className='btn py-0 my-0 button_fix' title="Pdf bolla con prezzi" >
                    <FontAwesomeIcon className=' py-0 my-0' icon={faFileInvoiceDollar} onClick={()=>scaricaPdf(element.id)} />
                </button>
                <button className='btn py-0 my-0 button_fix' title="Pdf bolla senza prezzi" >
                    <FontAwesomeIcon className=' py-0 my-0' icon={faFilePdf} onClick={()=>scaricaPdfNoPrezzi(element.id)} />
                </button>
            </td>
        )
    }

    const recuperaDatiModBolla = (dati) => { 
        let oggetto = {}
        console.log(dati , 'dati');
        Object.keys(dati).forEach(element => {
            //console.log();
            switch (element) {
                case 'infoCliente':
                    Object.keys(dati.infoCliente).forEach(element2 => {
                     
                        oggetto['anNominazioneCliente'] =dati?.infoCliente?.viewName
                        if (element2 === 'infoTipoAnCliente') {
                            oggetto['anTipoAnaNome'] = dati.infoCliente.infoTipoAnCliente.nome
                            oggetto['anTipo'] = dati.infoCliente.infoTipoAnCliente.id
                        }else {
                            oggetto['an'+element2.charAt(0).toUpperCase()+element2.slice(1)] = dati.infoCliente[element2]
                        }
                    });
                break;
                case 'infoTipoPag' :
                    oggetto.tipoPag = dati?.infoTipoPag?.nome
                break;
                case 'dataOraPartenza' :
                    if (!!dati[element]) {
                        const formattedDate = format(dati[element], "yyyy-MM-dd'T'HH:mm")
                        oggetto[element] = formattedDate
                    }
                break;
            
                case 'prods':
                    if (dati.prods.length) {
                        console.log(dati.prods , ' dati.prods');
                        
                        oggetto.prods= []
                        dati.prods.forEach(element2 => {
                            if (element2.stato === 1) {
                                Object.keys(element2.infoProd).forEach(element3 => {    
                                    if (element3 !== 'id' && element3 !== 'iva') {
                                        element2[element3] = element2.infoProd[element3]
                                    }
                                })
                                if (!!element2?.infoUnitaMisura) {
                                    element2.umId = element2.infoUnitaMisura.id
                                }
                                element2.seriali = element2.ProdSerialis.map((item)=>{return item.seriale}).join(';')
                                console.log(element2 , ' element2');
                                
                                oggetto.prods.push(element2)
                            }
                        })

                    }
                break;
            
            
                default:
                    oggetto[element] = dati[element]
                break;
            }
        });
        console.log(oggetto , 'oggetto');
        setInputsModifica(oggetto)
        setViewModifica(true)
    }

    const onChangeCreaBolla = async (stato) => {
        if (stato) {
            setViewAttendere(true)
            setViewCrea(false)
            await bolla_fetch.creaBollaUscita(inputs)
            .then(response => {
                setInputs({})
                readBolle()
                setErrors({})  
                setViewAttendere(false)
            }).catch(errors => {
                setViewCrea(true)
                setErrors(errors.response.data)
                //console.log(errors.response.data , ' bollaUscita errori');
            })
            await readBolle()
        
            
        }else {
            setViewCrea(false)
            setViewAttendere(false)
            setErrors({}) 
        }
        await readBolle()
    }

    const onCloseCreaMod = async (stato) => {
        if (stato) {
            setViewAttendere(true)
            setViewModifica(false)
            await bolla_fetch.modBollaUscita(inputsModifica)
            .then(response => {
                setInputsModifica({})
                readBolle()
                setViewAttendere(false)
                setErrors({})  
            }).catch(errors => {
                setViewModifica(true)
                setErrors(errors.response.data)
                //console.log(errors.response.data , ' bollaUscita errori');
            })
       
            await readBolle()
            
        }else {
            setViewModifica(false)
            setViewAttendere(false)
            setErrors({})  
        }
    }

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtra({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag ,orderMoreRecente : 'DESC', ...inputsFiltra })
            setAzzeraVar(0)
        }
    }

    const convertiData = (data) => {
            //console.log(format(new Date(data) ,'dd-MM-yyyy hh:mm') , ' Date ' , data);
            //console.log(data ,' Date 2' , (data+'').indexOf('Z'));
            if ((data+'').indexOf('Z') !== -1) {
                return format(new Date(data) ,'dd-MM-yyyy hh:mm') 
            }
            return format(new Date(data) ,'dd-MM-yyyy') 
        }
    //console.log(inputs , ' inputss');

    return(
        <div className="p-2">
            <PaginazioneTitoloPulsanteCrea titolo={'Ddt in uscita'} funCrea={()=> setViewCrea(true)} />

            <div className="px-3">
                <div className="border_filtra p-3 m-5" >
                    <FiltraBollaUscita inputs={inputsFiltra} onChangeValue={onChangeValueFiltra} />
            
                    <PulsantiFiltra
                        filtra={filtra}
                        azzera={readBolle}
                    />
                </div>

                <div className='d-flex justify-content-around flex-wrap w-100 px-3'>
                    <TabellaBolleUscita
                        array={arrayBolla}
                        azzeraVar={azzeraVar}
                        pageTot={pageTot}
                        Pulsanti={Pulsanti}
                        impaginazione={impaginazione}
                        fu={fu}
                    />
                </div>
            </div>

            
            {viewCrea &&<QuestionModal
                title={'Aggiungi ddt in uscita'}
                text={
                    <CampiBollaUscita
                        inputs={inputs} 
                        errors={{...errors , ...campiObSegnale}}
                        onChange={onChangeValue}
                        />
                   }
                YesLabel='Salva'
                yesDisabled={!statoAvanti}
                onClose={onChangeCreaBolla}
                classi=' modal-xxl '
            />}

            
            {!!viewModifica && <QuestionModal
                title={<h3 className="text-white d-flex align-items-center ps-3" >
                    <FontAwesomeIcon className="me-3 text-white" icon={faFileLines} />
                    Modifica DDT N° { inputsModifica?.id+'/'+inputsModifica?.annoId +(!!inputsModifica?.codiceMag ? '/'+inputsModifica.codiceMag : '') + ' del ' +convertiData(inputsModifica?.dataFattura) }
                </h3>}
                text={
                    <div>
                        <CampiBollaUscita onChange={onChangeModifica} inputs={inputsModifica} errors={{...errors , ...campiObSegnale}} />
                    </div>
                }
                yesDisabled={!statoAvantiMod}
                onClose={onCloseCreaMod}
                classi=' modal-xxl '
            />}

            {!!viewBolla && 
                <InfoModal
                    title={ 
                    <h3 className="text-white d-flex align-items-center ps-3" >
                        <FontAwesomeIcon className="me-3 text-white" icon={faFileLines} />
                        DDT N° { viewBolla?.id+'/'+viewBolla?.annoId+(!!viewBolla?.codiceMag ? '/'+viewBolla.codiceMag : '') + ' del ' +convertiData(viewBolla?.dataFattura) }
                    </h3>
                    }
                    text={
                        <ReadCampiBolla inputs={viewBolla} cliente={true} />
                    }
                    canClose
                    handleClose={()=>setViewBolla(false)}
                    className = 'modal-xl'
                />
            }
            { !!viewAttendere && <Attendere />}

        </div>
    )
}