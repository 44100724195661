import React, {useState} from "react"
import AnagraficaProdotto from "./sezioni/AnagraficaProdotto";
import CampiProdSezione from "./sezioni/CampiProdSezione";
// import {  daEuCent  } from "../riutilizabili/daEurCent.js";
import sezioneService from "../../fetch/classSezione.js";
import QuestionModal from "../../riutilizabili/QuestionModal.js"
import SelezionaSezioneDest from "./sezioni/SelezionaSezioneDest.js";
import Modal from 'react-bootstrap/Modal';
import Switch2 from "../../riutilizabili/Switch2.js";
import FormError from "../../riutilizabili/FormError.js";


export default function CreaProdSezione ({
    inputs,
    onChange ,
    errors
}) {
    // const [inputs ,setInputs] = useState({})
    const [viewSelezionaSezione ,setViewSelezionaSezione] = useState(false)
    
    const abilitaVisualizzazione = () => {
        if(!!inputs?.quantita && !!inputs?.umId) {
            setViewSelezionaSezione(true)
        }else {
            alert('Seleziona prima quantita e unità di misura')
        }
    }

    const onChangeSelezionaSezione = async (stato) => {
        if(stato) {

        }else{
            setViewSelezionaSezione(false)
        }
    }
    
    const selezionaSezzione = async (sezioneId , percorso) => {
        const sezione = await sezioneService.get({id: sezioneId})
        onChange('infoSezione', sezione.data[0])
        onChange('percorsoSezione', percorso)
        onChange('sezioneId', sezioneId)
        setViewSelezionaSezione(false)
    }

    const Pulsanti = (id) => {
        const element = id.id.original;
        return(

            // <button
            //     onClick={()=>selezionaSezzione(element.sezioneId , element.percorso)}
            //     className='btn btn-primary m-2' 
            // >
            //     Seleziona
            // </button>
            <td>
                <Switch2
                    stato={element.sezioneId === inputs.sezioneId ? true : false}
                    onChangeValue={()=>selezionaSezzione(element.sezioneId , element.percorso)}
                />

            </td>
      
        )
    }

    //console.log(inputs , ' inputs CreaProdSezione')
    return (
        <div>
            <div>
                <AnagraficaProdotto
                    inputs={inputs}
                    onChange={onChange}
                    errors={errors}
                />
                <CampiProdSezione
                    value={inputs}
                    onChange={onChange}
                    errors={errors}
                />
                <div className="ms-5 ps-1">
                    <button className='btn btn-primary m-3' onClick={abilitaVisualizzazione} > Seleziona sezione di destinazione </button>
                    <p className="ms-3">Sezione di destinazione : {inputs?.sezioneId ? inputs.infoSezione.nome + '(percorso sezione : '+inputs?.percorsoSezione + ') '  : '' } </p>
                    <FormError errors={errors} field={'sezioneId'} />
                </div>
                {!!viewSelezionaSezione && <QuestionModal
                    title={'Seleziona sezione di destinazione'}
                    text={
                        <SelezionaSezioneDest 
                            quantita={inputs.quantita}
                            unitaMisuraId={inputs.umId}
                            Pulsanti={Pulsanti}
                            sezioneId={!!inputs?.sezioneId ? inputs.sezioneId : 4}
                        />
                    }
                    AltFooter={
                        <Modal.Footer>
                            <button type="button" onClick={() => onChangeSelezionaSezione(false)} className="btn btn-secondary">Annulla</button>
                        </Modal.Footer>
                    }
                    onClose={onChangeSelezionaSezione}
                />}
            </div>
        </div>
    )
}