import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import anCliente_fetch from '../fetch/anCliente_fetch';
import catProd_fetch from '../fetch/catProd_fetch';
import prodotti_fetch from '../fetch/prodotti_fetch';
import create_autocompleteWithService from '../riutilizabili/autocomplite/create_autocompleteWithService';
import InputForm from '../riutilizabili/forms/InputForm';
import Switch from '../riutilizabili/forms/Switch';
import TextAreaForm from '../riutilizabili/forms/TextAreaForm';
import sezioneService from '../fetch/classSezione';
import { daCentEu, daEuCent } from '../riutilizabili/daEurCent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import QuestionModal from '../riutilizabili/QuestionModal';
import InfoModal from '../riutilizabili/InfoModal';
import Modal from 'react-bootstrap/Modal'
import classSezione from '../fetch/classSezione';
import anFornitore_fetch from '../fetch/anFornitore_fetch';

export default function CampiProdotti({classiDiv , value , onChange , errors}) {
    const navigate = useNavigate();

    const [categorie , setCategorie]  = useState([]); 
    const [viewCreaCat , setViewCreaCat]  = useState(false); 
    const [inputsCreaCat , setInputsCreaCat] = useState({})
    const [errorsCreaCat , setErrorsCreaCat] = useState({})
    const [magazzinoPrincipale , setMagazzinoPrincipale] = useState({})

    useEffect( ()=>{
        saveCategoria()
        selezionaMagazzino()
      },[])
    
    const saveCategoria = async () => {
        await prodotti_fetch.getCatAll()
        .then(response => {
            //console.log(response.data , ' saveCategoria');
            setCategorie(response.data);
        })
        .catch(error=> {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const Fornitore = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anFornitore_fetch.getAll({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item?.ragioneSociale ? item?.ragioneSociale : item.nome+' '+item.cognome,
        itemToString: item => item?.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome?.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Fornitore ',
        value: value?.fornitore_nome ,
        onChange : ({value, item}) => {onChange('fornitoreId', item?.id) ; onChange('fornitore_nome', item?.viewName); onChange('fornitoreId', item?.id) ;} ,
        placeholder: 'inserisci nome fornitore',
        className: 'W-25'
    });

    const Categorie = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => catProd_fetch.get({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona categoria' ,
        value: value?.categoria_nome ,
        onChange : ({value, item}) => onChangeCategorie(item) ,
        placeholder: 'inserisci nome categoria',
        redflag : !!errors?.categoriaId ,

    });

    const SottoCategorie = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => catProd_fetch.get({
            catPadreId : value.categoriaId,
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona sottocategoria' ,
        value: value?.sottoCategoria_nome ,
        onChange : ({value, item}) => onChangeSottoCategorie(item) ,
        placeholder: 'inserisci nome sottocategoria',
        redflag : !!errors?.idSottoCategoria ,
    });

    const onChangeSottoCategorie = (item) => {
        if (item) {
            onChange('idSottoCategoria' , item.id)
            onChange('sottoCategoria_nome' , item.nome ) ; 
            onChange('idSottoCategoria' , item.id)
        }else {
            onChange('sottoCategoria_nome' , "") ;
            onChange('idSottoCategoria' , "" )
        }
    }
    const onChangeCategorie = (item) => {
        if (item) {
            onChange('categoriaId' , item.id)
            onChange('categoria_nome' , item.nome ) ; 
            onChange('categoriaId' , item.id)
        }else {
            onChange('categoria_nome' , "") ;
            onChange('categoriaId' , "" )
        }
    }

    const UnMisuraAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getUnMisura({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona unità di misura' ,
        value: value?.nomeUnitaMisura ,
        onChange : ({value, item}) => {onChange('umId' , item?.id ? item.id : ''); onChange('nomeUnitaMisura' , item?.nome ? item.nome : ''); onChange('umId' , item?.id ? item.id : '');},
        placeholder: 'inserisci nome sezione',
        redflag : !!errors?.umId ,
    });

    const onChangeCreaCat = (name , value) => {
        let tmp = {...inputsCreaCat};
        tmp[name]= value;
        console.log(name , value , tmp);
        setInputsCreaCat(tmp);
    }

    const CreaSottoCategorie = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => catProd_fetch.get({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona categoria' ,
        value: inputsCreaCat?.categoria_nome ,
        onChange : ({value, item}) => onChangeCreaSottoCategorie(item) ,
        placeholder: 'inserisci nome categoria',
        redflag : !!errors?.catPadreId ,

    });

    const onChangeCreaSottoCategorie = (item) => {
        if (item) {
            onChangeCreaCat('catPadreId' , item.id)
            onChangeCreaCat('categoria_nome' , item.nome) ; 
        }else {
            onChangeCreaCat('categoria_nome' , "") ;
            onChangeCreaCat('catPadreId' , "" )
        }
    }

    const onCloseCreate = async (stato) => {
        if (stato) {
            const result = await catProd_fetch.create(inputsCreaCat);
            if (result.status === 200) {
                setViewCreaCat(false)
                setErrorsCreaCat({})
                if (viewCreaCat === 'sottocategoria') {
                    onChange('sottoCategoria_nome' , result?.data?.nome) ;
                    onChange('idSottoCategoria' , result?.data?.id )
                }else{
                    onChange('categoriaId' , result?.data?.id)
                    onChange('categoria_nome' , result?.data?.nome ) ; 
                }
            }else {
                setErrorsCreaCat(result.data)
            }
        } else {
            setViewCreaCat(false)
            
        }
        setInputsCreaCat({})
    }

    const selezionaMagazzino = async () => {
        const magazzini = await classSezione.get({magazzinoPrincipale : 1})
        console.log(magazzini.data , ' magazzini.data');
        setMagazzinoPrincipale(magazzini.data)
    }

    const onChangeMagazzinoPrincipale = (item) => {
        if (!!item) {
            let oggetto = [item.id , ...item?.sezioniFigli.map(el => el.id)]
            console.log(item , ' item mag princ',oggetto);
    
            onChange('arraySezioneId',oggetto)
            onChange('magazzinoPrincipale_nome',item.nome)
            
        }else {
            onChange('arraySezioneId',[])
            onChange('magazzinoPrincipale_nome','')

        }
    }
    
    const MagazzinoPrincipale = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classSezione.get({
            magazzinoPrincipale : 1,
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona Magazzino' ,
        value: value?.magazzinoPrincipale_nome ,
        onChange : ({value, item}) => onChangeMagazzinoPrincipale(item) ,
        placeholder: 'inserisci nome magazzino',
        redflag : false ,

    });

    console.log(inputsCreaCat , ' inputsCreaCat viewCreaCat',viewCreaCat);
    return(
        <div className={classiDiv}>
            <InputForm 
                label='Nome*' 
                type='text' 
                placeholder='Inserisci nome'
                name='nome'
                value={!!value?.nome ? value.nome : ''}
                onChange={(name , value) => onChange(name , value)}
                classi=' w-25 m-2'
                errors={errors}
            />
            <div className='w-50 m-2'>
                <TextAreaForm
                    label = 'Descrizione'
                    name = 'descrizione' 
                    value = {!!value.descrizione ? value.descrizione : ''}
                    onChange ={(name , value) => onChange(name , value)}
                    classi=' w-75 m-2'
                    errors ={errors}
                />
            </div>

            <div className='w-25 m-2 d-flex align-items-center'>
                <span className='m-2 col-10'>
                    <Categorie />
                </span>
                <button className='btn btn-primary rounded-5 m-2 text-white' title='crea categoria' onClick={()=>setViewCreaCat('categoria')}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            {!!value.categoriaId && 
            <div className='w-25 m-2 d-flex align-items-center'>
                <span className='m-2 col-10'>
                    <SottoCategorie />
                </span>
                <button className='btn btn-primary rounded-5 m-2 text-white' title='crea sottocategoria' onClick={()=>setViewCreaCat('sottocategoria')}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>}

            <InputForm 
                label='Codice a barre' 
                type='text' 
                maxLenght='13'
                placeholder='Inserisci codice a barre'
                name='codBarre'
                value={!!value.codBarre ? value.codBarre : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-25 m-2'
                errors={errors}
            />

            <InputForm 
                label='Costo' 
                type='text' 
                placeholder='Inserisci costo'
                name='costo'
                value={!!value.costo ? value.costo : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-25 m-2'
                errors={errors}
            />

            <InputForm 
                label='prezzo di vendita' 
                type='text' 
                placeholder='Inserisci prezzo di vendita'
                name='prezzoVendita'
                value={!!value.prezzoVendita ? value.prezzoVendita : ''}
                onChange={(name , value) => onChange(name ,value)}
                classi='w-25 m-2'
                errors={errors}
            />

            <InputForm 
                label='Codice produttore *' 
                type='text' 
                placeholder='Inserisci codice produttore'
                name='codProd'
                value={!!value.codProd ? value.codProd : ''}
                onChange={(name , value) =>{ onChange(name , value); onChange('rifInterno' , 'NTP-'+value)}}
                classi='w-25 m-2'
                errors={errors}
            />
            <InputForm 
                label='Produttore' 
                type='text' 
                placeholder='Inserisci produttore'
                name='produttore'
                value={!!value.produttore ? value.produttore : ''}
                onChange={(name , value) =>{ onChange(name , value)}}
                classi='w-25 m-2'
                errors={errors}
            />
            
            <InputForm 
                label='Codice interno *' 
                type='text' 
                placeholder='Inserisci codice interno'
                name='rifInterno'
                value={!!value.rifInterno ? value.rifInterno : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-25 m-2'
                errors={errors}
            />

            <div className='w-25 m-2'>
                <TextAreaForm
                    label = 'note'
                    name = 'note' 
                    value = {value.note ? value.note : ''}
                    onChange ={(name , value) => onChange(name , value)}
                    classi='w-25 m-2'
                    errors ={errors}
                />
            </div>

            
            <InputForm 
                label='ASIN' 
                type='text' 
                placeholder='Inserisci ASIN'
                name='asin'
                maxLenght={11}
                value={!!value.asin ? value.asin : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-25 m-2'
                errors={errors}
            />

            
            <InputForm 
                label='EAN' 
                type='text' 
                placeholder='Inserisci EAN'
                name='ean'
                value={!!value.ean ? value.ean : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-25 m-2'
                errors={errors}
            />
            
            <div className='w-25 m-2 me-5 ms-3'>
                <Fornitore />
            </div>

            <Switch
                stato={value.automaticReorder ? value.automaticReorder : 0}
                number='automaticReorder'
                label='Segnala quantita bassa in magazzino'
                onChangeValue={(value , name) => onChange(name , value)}
                width='21%'
                classe={'m-0 p-0 mb-4 pb-1 pt-2 me-3 ms-1 text-center'}
            />
        

            {!!value.automaticReorder && <InputForm 
                label='quantita minima' 
                type='text' 
                placeholder='Inserisci quantita minima'
                name='qtMin'
                value={!!value.qtMin ? value.qtMin : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-25 m-2'
                errors={errors}
            />}

            <div className='w-25 m-2'>
                <UnMisuraAutocomplite />
            </div>
            <div className='w-25 m-2'>
                <MagazzinoPrincipale />
            </div>

            {!!viewCreaCat && <InfoModal
                title={'Crea '+viewCreaCat}
                text={<div>
                    <InputForm 
                        label='nome' 
                        type='text'
                        placeholder='Inserisci nome'
                        name='nome'
                        value={!!inputsCreaCat.nome ? inputsCreaCat.nome : ''}
                        onChange={(name , value) => onChangeCreaCat(name , value)}
                        classi='w-25 m-2'
                        errors={errorsCreaCat}
                    />
                    {viewCreaCat === 'sottocategoria' &&
                    <div className='w-25'>
                        <CreaSottoCategorie />
                    </div>
                    }
                </div>}
                canClose={true}
                handleClose={() => onCloseCreate(false)}
                alternativeFooter={
                    <Modal.Footer>
                        <button type="button" onClick={() => onCloseCreate(false)} className="btn btn-secondary">Annulla</button>
                        <button type="button" onClick={() => onCloseCreate(true)} className="btn btn-primary">Conferma</button>
                    </Modal.Footer>
                }
            />}
            
        </div>
    )
}