import React from "react";
import Tabella from "../riutilizabili/Tabella";
import { daCentEu } from "../riutilizabili/daEurCent";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import ViewProdPopUp from "./ViewProdPopUp";



export default function TabellaProdotti({
    array,
    Pulsanti ,
    impaginazione ,
    pageTot ,
    azzeraVar,
    fu
}) {
    
    const columns = [
        {
        label: 'Nome',
        nome: 'nome',
        altCol: (item)=> {
            return(
                <>
                    <ViewProdPopUp
                        icona={item?.infoProd?.nome}
                        prodAnId={item?.infoProd?.id}
                    />
                </>
            )
        }
        },
        {
        label: 'Codice a barre',
        nome: 'codBarre',
        },
        {
            label: 'Unità di misura',
            nome: 'umId',
            altCol: (item)=> {
                console.log(item , ' item');
                
                return item?.infoUnitaMisura?.nome
            }
            },
        {
        label: 'Quantità iniziale',
        nome: 'quantita',
        },
        {
        label: 'Quantità residua',
        nome: 'quantitaResidua',
        },
        {
        label: 'Prezzo',
        nome: 'prezzoUnitarioCent',
         altCol: (item)=> {
            return(
                <>
                    {daCentEu(item.prezzoUnitarioCent)}
                </>
            )
        }
        },
        {
            label: 'Fornitore',
            nome: 'Fornitore',
            altCol: (item)=> {
                console.log(item , ' item');
                
                return item?.infoFornitore?.viewName
            }
        },
        {
        label: 'Sezione',
        nome: 'infoSezione',
         altCol: (item)=> {
            let percorso =''
            if (!!item.infoSezione) {
                percorso = item?.infoSezione?.nome
                let og = item.infoSezione
                while (og.padreId !== null) {
                    percorso = og.sezionePadre.nome+ ' / '+percorso
                    og = og.sezionePadre
                }
            }
            return(
                <>
                    {!!item.infoSezione ? percorso : 'Sezione non assegnata'}
                </>
            )
        }
        },
    ]

    return(
        <div className="w-100 m-4" >
            <TabellaPaginazione
                dati={array}
                colonne={columns}
                Pulsanti={Pulsanti}
                impaginazione={impaginazione}
                totalePag={pageTot}
                azzera={azzeraVar}
                fu={fu}
            />
        </div>
    )
}