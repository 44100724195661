import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreaAnagrafica from '../anagrafica/CreaAnagrafica';
import SelezionaAnagrafica from '../anagrafica/SelezionaAnagrafica';
import ViewAnagrafica from '../anagrafica/ViewAnagrafica';
import anCliente_fetch from '../fetch/anCliente_fetch';
import InputForm from '../riutilizabili/forms/InputForm';
import Switch from '../riutilizabili/forms/Switch';
import InfoModal from '../riutilizabili/InfoModal';
import QuestionModal from '../riutilizabili/QuestionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function CampiUsers({
    classiDiv , 
    value , 
    onChange , 
    errors , 
    filtra = false,
    visualizza = false ,
    onChangeError
}) {
    const [viewAn , setViewAn ] = useState(false);
    const [selectwAn , setSelectwAn ] = useState(false);
    const [creaAn , setCreaAn ] = useState(false);
    const [creaAnDati , setCreaAnDati ] = useState({});
    const [anagraficaSelezionata , setAnagraficaSelezionata ] = useState(null);
    const [errorCrea, setErrorCrea] = useState([]);
    const [ripetiPw, setRipetiPw] = useState('')
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{
         leggiAnagrafica()
      },[])

    const assegnaAnagrafica = (id , nome) => {
        setAnagraficaSelezionata({
            id : id ,
            nome : nome
        });
        setSelectwAn(false)
        onChange('anagrId' , id)
    }

    const leggiAnagrafica = async () => {
        if (value.anagrId) {
            await anCliente_fetch.get({id : value.anagrId})
            .then(anagrafica => {
                const nome = anagrafica.data[0].ragioneSociale ? anagrafica.data[0].ragioneSociale : anagrafica.data[0].nome +' '+ anagrafica.data[0].cognome ;
                setAnagraficaSelezionata({
                id : anagrafica.data[0].id ,
                nome : nome
                });
            })
              .catch(error => {
                if (error.response.status === 406) {
                    navigate("/login");
                }
            })
            
            
        }
    }

    const chiudiSelezionaUt = () => {
        setAnagraficaSelezionata({});
        setSelectwAn(false);
        onChange('anagrId' , '');
    }

    const saveCreaAn = async () => {
        anCliente_fetch.create(creaAnDati)
        .then(response => {
            setAnagraficaSelezionata({
                id : response.data.id ,
                nome : response.data.nome
            })
            onChange('anagrId' , response.data.id)
            setCreaAn(false)
        })
        .catch(error=> {
            if (error.response.status === 406) {
                navigate("/login");;
            }else {
                setErrorCrea(error.response.data)
  
            }
        })
    }

    const onChangeTipoAn = (tipoAn) => {
        switch (tipoAn) {
          case 'liberoProfessionista':
            setCreaAnDati(values => ({ ...values, liberoProfessionista: 1 }))
            setCreaAnDati(values => ({ ...values, azienda: 0 }))
            setCreaAnDati(values => ({ ...values, privato: 0 }))
            setCreaAnDati(values => ({ ...values, entePubblico: 0 }))
          break;
          case 'azienda':
            console.log(' values azienda');
            
            setCreaAnDati(values => ({ ...values, liberoProfessionista: 0 }))
            setCreaAnDati(values => ({ ...values, azienda: 1 }))
            setCreaAnDati(values => ({ ...values, privato: 0 }))
            setCreaAnDati(values => ({ ...values, entePubblico: 0 }))
          break;
          case 'privato':
            setCreaAnDati(values => ({ ...values, liberoProfessionista: 0 }))
            setCreaAnDati(values => ({ ...values, azienda: 0 }))
            setCreaAnDati(values => ({ ...values, privato: 1 }))
            setCreaAnDati(values => ({ ...values, entePubblico: 0 }))
          break;
          case 'entePubblico':
            setCreaAnDati(values => ({ ...values, liberoProfessionista: 0 }))
            setCreaAnDati(values => ({ ...values, azienda: 0 }))
            setCreaAnDati(values => ({ ...values, privato: 0 }))
            setCreaAnDati(values => ({ ...values, entePubblico: 1 }))
          break;
      }
      }

      const controlloLunPw = () => {

        const t = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/;
        // t.test(stringa)
        console.log({ prova: value.password_hash, risultato : t.test(value?.password_hash) })
        if (value?.password_hash!= '') {
            if (!t.test(value?.password_hash)) {
                onChangeError({
                    password_hash: 'Criterio password non rispettato ',
                })
            }else {
                let tmp = { ...errors }
                delete tmp.password_hash
                onChangeError(tmp)
            }
    
            if (ripetiPw != '') {
                controllaPw()
            }
        }else{
            onChangeError({})
        }
    }
    const controllaPw = () => {
        if (value?.password_hash != ripetiPw) {
            onChangeError({
                password_hash: 'le passwords non coincidono',
                ripetiPassword: 'le passwords non coincidono',
            })
        } else {
            const t = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/;

            if (!t.test(value?.password_hash)) {
                onChangeError({
                    password_hash: 'Criterio password non rispettato ',
                })
            }else {
                let tmp = { ...errors }
                delete tmp.password_hash
                delete tmp.ripetiPassword
                onChangeError(tmp)
            }
        }
    }

    //console.log(value , ' value');
    return(
        <div className={classiDiv}>
            <InputForm
                label='Username' 
                type='text' 
                placeholder='Inserisci username'
                name='username'
                value={value.username ? value.username : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-25'
                errors={errors}
            />

            {!filtra &&<div className='d-flex col-7'>
                <InputForm
                    label={'password'}
                    labelAlt={ 
                      <FontAwesomeIcon className="ms-2" title="8 caratteri , almeno una lettera maiuscola , almeno una lettera minuscola, un carattere speciale e un numero " icon={faCircleInfo} />
                    }
                    name="password_hash"
                    type={!!showPassword ? 'text' : "password"}
                    placeholder="Inserisci password"
                    value={!!value?.password_hash ? value?.password_hash : ''}
                    onChange={(nome, valore) => onChange(nome, valore)}
                    errors={errors}
                    classi={' col-5 pe-2'}
                    onBlur={controlloLunPw}
                    classInput={!!visualizza ? 'border-0 bg-white ' : ' '}
                    disabled={visualizza}
                />
                
                <InputForm
                    label='Ripeti password'
                    name="ripetiPassword"
                    type={!!showPassword ? 'text' : "password"}
                    placeholder="Rinserisci password"
                    value={!!ripetiPw ? ripetiPw : ''}
                    onChange={(nome, valore) => setRipetiPw(valore)}
                    errors={errors}
                    classi={' col-5 pe-2'}
                    onBlur={controllaPw}
                />

                <button className="btn border-0" onClick={()=>setShowPassword(!showPassword)}>
                {!!showPassword ?
                        <FontAwesomeIcon icon={faEye} />
                        :
                        <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
            </div>}

            <InputForm 
                label='Email' 
                type='text' 
                placeholder='Inserisci email'
                name='email'
                value={value.email ? value.email : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-50'
                errors={errors}
            />
            <Switch
                stato={value.status}
                onChangeValue={(name , value) => onChange(value , name)}
                number="status"
                label ='Abilitato'
                classe={'mt-2 pt-1 pb-3'}
            />

            {/*{!filtra && <div>
                <button className='btn btn-primary m-3' onClick={() => setViewAn(true)} >Vai ad anagrafica</button> 
                <button className='btn btn-primary m-3' onClick={() => setSelectwAn(true)} >Seleziona anagrafica</button> 
                <button className='btn btn-primary m-3' onClick={() => setCreaAn(true)} >Crea anagrafica</button> 
                {anagraficaSelezionata && anagraficaSelezionata.nome}
                
            </div>}

             {viewAn &&
                <InfoModal 
                    title={'Anagrafica'}
                    text={<ViewAnagrafica id={value.anagrId} />}
                    handleClose={() => setViewAn(false)}
                    canClose={true}
                />
            }
            
            {selectwAn &&<QuestionModal
            title='Seleziona anagrafica'
            text={<SelezionaAnagrafica onChange={(e , b)=> assegnaAnagrafica(e , b)} />}
            onClose={chiudiSelezionaUt}
            AltFooter={
                <div className='m-5'>
                    <button className='btn btn-primary float-end' onClick={chiudiSelezionaUt}>
                        Chiudi
                    </button>
                </div>
            }
            />

        }
        {creaAn &&<QuestionModal
            title='Crea anagrafica'
            text={
                <div>
                    <CreaAnagrafica
                        values={creaAnDati}
                        onChange={(name , value)=> setCreaAnDati(values => ({ ...values, [name]: value }))}
                        onChangeError={(name , value)=> setErrorCrea(values => ({ ...values, [name]: value }))}
                        error={errorCrea}
                        onChangeTipoAn={onChangeTipoAn}
                    />
                </div>
            }
            onClose={saveCreaAn}
        />} */}
        </div>
    )
}