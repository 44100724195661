import React, {useEffect, useState } from "react";
import Tabella from '../../../riutilizabili/Tabella.js'
import prodSezioneService from '../../../fetch/classProdSezzione.js'
import FiltraSezioni from "../../../sezioni/FiltraSezioni.js";
import PulsantiFiltra from "../../../riutilizabili/PulsantiFiltra.js";
import { daEuCent } from "../../../riutilizabili/daEurCent.js";
import ProdottiSezione from "../../../sezioni/ProdottiSezione.js";


export default function SelezionaSezioneDest({
    Pulsanti ,
    quantita ,
    unitaMisuraId,
    sezioneId
}) {

    const [arraySezioni , setArraySezioni] = useState([]);
    const [inputsFiltra, setInputsFiltra] = useState({});
    const [errors] = useState({});

    useEffect(()=> {
        filtraSezioniDisp() 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[quantita,unitaMisuraId])

    const filtraSezioniDisp = async (param) => {
        console.log(param, ' paramparam')
        const sezioni = await prodSezioneService.getSpostaInternoMag({
            sezioneId
        })

        console.log(sezioni , ' sezioni disponibili')
        setArraySezioni(sezioni.data.filter(value => value !== null))
    }

    
    const onChange = (nome, valore) => {
        if (nome.slice(-4) === 'Cent') {
            //console.log(valore, ' valore allarme')
            const cent = daEuCent(valore)
            setInputsFiltra(values => ({ ...values, [nome]: cent }))
            setInputsFiltra(values => ({ ...values, [nome.slice(0, nome.length - 4) + 'Eur']: valore }))
        } else {

            setInputsFiltra(values => ({ ...values, [nome]: valore }))
        }
        filtraSezioniDisp({...inputsFiltra , [nome]: valore })
    }

    const columns = React.useMemo(
        () => [
            {
            Header: 'Percorso sezione',
            accessor: 'percorso',
            },
            {
            Header: 'Sezione',
            accessor: 'nome',
            Cell: ({ row: { original } }) => {

                return(
                    <ProdottiSezione
                        sezioneId={original.id}
                        nomeSezione={original.nome}
                        icona={original.nome}
                    />
                )
            }
            },
            {
            Header: 'Spazio disponibile',
            accessor: 'quantitaDisp',
            },
            {
            Header: 'Spazio Occupato',
            accessor: 'quantitaOc',
            }
        ],[]
    )

    return(
        <div>
            <div >
                <FiltraSezioni 
                    value={inputsFiltra}
                    onChange={onChange}
                    errors={errors}
                />
                <PulsantiFiltra
                   filtra={()=>filtraSezioniDisp(inputsFiltra) } 
                   azzera={()=>{filtraSezioniDisp(); setInputsFiltra({})} } 
                />
            </div>
            <Tabella 
                columns={columns}
                data={arraySezioni}
                Pulsanti={Pulsanti}
            />
        </div>
    )
}