import { useState } from "react"
import prodotti_fetch from "../fetch/prodotti_fetch"
import classProdSezzione from "../fetch/classProdSezzione"
import InfoModal from "../riutilizabili/InfoModal"
import { useNavigate } from "react-router"
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione"



export default function ViewProdPopUp({
    prodAnId ,
    icona
}) {
    const [datiProd , setDatiProd] = useState(false)
    const [prods , setProds] = useState(false)
    const navigate = useNavigate();

        const [pageTot , setPageTot] = useState(0);
        const [pageSize , setPageSize] = useState(10);
        const [page , setPage] = useState(1);
        const [azzeraVar , setAzzeraVar] = useState(0)
        const [fu , setFu] = useState(0)
        const [inputsFiltra , setInputsFiltra] = useState({})
    
    const visualizzaProd = async () => {
        const datiProd = await prodotti_fetch.get({id : prodAnId})
        console.log(datiProd , ' datiProd',prodAnId);
        
        const array = datiProd.data[0];
        let newArray = {}
        const datiPrendere = [
            'nome', 
            'descrizione', 
            'infoCategoria' ,
            'infoSottoCategoria' ,
            'infoFornitore' ,
            'codBarre' ,
            'prezzoVendita' ,
            'costo' ,
            'rifInterno' ,
            'note' ,
            'asin' ,
            'qtMin' ,
            'automaticReorder' ,
            'posizioneMg' ,
        ]
        for (let index = 0; index < Object.keys(array).length; index++) {
            const element = Object.keys(array)[index];
            //console.log(element , ' element');
            if (datiPrendere.includes(element)) {
                if (element === 'infoCategoria') {
                    newArray['categoria'] = array[element].nome
                }else if (element === 'infoSottoCategoria') {
                    newArray['sotto Categoria'] = array[element]?.nome
                }else if (element === 'infoFornitore') {
                    newArray['fornitore'] = array[element]?.viewName
                } if (element === 'codBarre') {
                    newArray['codice a barre'] = array[element]
                } if (element === 'prezzoVendita') {
                    newArray['prezzo di vendita'] = array[element]
                } if (element === 'rifInterno') {
                    newArray['riferimento interno'] = array[element]
                } if (element === 'qtMin') {
                    newArray['quantita minima'] = array[element]
                }if (element === 'nome') {
                    newArray['nome'] = array[element]
                } if (element === 'automaticReorder') {
                    newArray['Riordino automatico'] = array[element] ? 'si' : 'no'
                }  if (element === 'posizioneMg') {
                } {
                    if (!datiPrendere.includes(element)) {
                        newArray[element] = array[element]
                        
                    }
                }
            }
        }     
        setDatiProd(newArray)
        const prods = await classProdSezzione.get({prodottoId: prodAnId , pageSize , page , orderId :'ASC'})
        setProds(prods.data.data)
        setPageTot(prods.data.pageTot)
    }

    console.log(datiProd , prods , ' datiProd , prods');
    

    const filtra = async (param) => {
        await classProdSezzione.get({prodottoId: prodAnId , pageSize , page , orderId :'ASC' , ...param})
        .then(response => {
            //console.log(response.data , ' response.data');
            setProds(response.data.data)
            setPageTot(response.data.pageTot)
            setFu(fu+1)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    
    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtra({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }

    const columns = [
        {
            label: 'Nome',
            nome: 'nome',
        },
        {
            label: 'riferimento interno',
            nome: 'rifInterno',
        },
        {
            label: 'Quantità Iniziale',
            nome: 'quantita',
        },
        {
            label: 'Quantità residua',
            nome: 'quantitaResidua',
            altCol: (item)=> {
                return item.quantitaResidua+''
            }
        },
        {
            label: 'Unità di misura',
            nome: 'umId',
            altCol: (item)=> {
                return item?.infoUnitaMisura?.nome
            }
        },
        {
            label: 'Posizione iniziale',
            nome: 'PosizioneIniziale',
            altCol: (item)=> {
                return item?.infoSezione?.nome
            }
          },
        {
            label: 'Posizione Attuale',
            nome: 'Posizione',
            altCol: (item)=> {
                return (!!item?.bollaUscitaId ? 'Bolla uscita : '+item?.bollaUscitaId +'/'+item?.bollaUscitaAnnoId +(!!item?.infoSezione?.codice ? '/'+item.infoSezione?.codice : '') : 'Sezione : '+item?.infoSezione?.nome  )
            }
        },
    ]
    

    return(
        <>
            <button className="btn" onClick={visualizzaProd}>
                {icona}
            </button>

            {!!prods &&<InfoModal
                title={'Prodotto '+datiProd.nome}
                text={<div>
                    <div className="d-flex flex-wrap border border-primary rounded m-2">
                        {Object.keys(datiProd).map((item , key) => {
                                    console.log(item, ' itemc ', datiProd[item]);
                                    return(
                                        <div key={key} className="p-2 d-flex flex-column  m-4" >
                                            <span className='fw-bold' > {item} </span>
                                            {datiProd[item] !== null ? datiProd[item] : ' '}
                                        </div>
                                    )
                                })}
                    </div>
                    <div className='p-3 pt-5 pe-4'>
                        <TabellaPaginazione
                            colonne={columns}
                            dati={prods}
                            totalePag={pageTot}
                            azzera={azzeraVar}
                            impaginazione={impaginazione}
                            fu={fu}
                        />
                    </div>
                    
                </div>}
                canClose
                handleClose={()=>setProds(false)}
                classi=' modal-xxl '
            />}
        </>
    )
}