import { 
    faCheck,
    faEdit,
    faEye,
    faPlus,
    faSearch,
    faTrash,
    faUserPlus,
    faUsers,
    faUserXmark 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import gruppi_fetch from '../fetch/gruppi_fetch';
import InfoModal from '../riutilizabili/InfoModal';
import PulsantiFiltra from '../riutilizabili/PulsantiFiltra';
import QuestionModal from '../riutilizabili/QuestionModal';
import SelezionaUser from '../riutilizabili/SelezionaUser';
import Tabella from '../riutilizabili/Tabella';
import RicercaCampiGruppi from './RicercaCampiGruppi';
import PaginazioneTitoloPulsanteCrea from '../riutilizabili/PaginazioneTitoloPulsanteCrea';
import PermessiGruppi from './PermessiGruppi';

export default function ReadGruppi() {
    const navigate = useNavigate();
 
    const [datiGruppi, setDatiGruppi] = useState([]);
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState([]);
    const [datiCrea , setDatiCrea] = useState({});
    const [datiModifica , setDatiModifica] = useState({});
    const [datiAddUser , setDatiAddUser] = useState({});
    const [datiView , setDatiView] = useState({});
    const [datiViewUsers , setDatiViewUsers] = useState({});
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [viewView , setViewView ] = useState(false);
    const [viewAddUser , setViewAddUser] = useState(false);
    const [viewAddUserSelectUser , setViewAddUserSelectUser] = useState(false);
    const [viewViewUsers , setViewViewUsers ] = useState(false);
    const [viewDeleteGroup , setViewDeleteGroup ] = useState(false);

    useEffect( ()=>{
        readgruppi()
      },[])

    const readgruppi = async () => {
        await gruppi_fetch.getAll()
         .then(response => {
            setDatiGruppi(response.data);
            setInputs({});
         })
         .catch(error => {
            //  console.log( error);
            //  console.log(error.status === 406);
             if (error.status === 406) {
                 navigate("/login");;
             }else{
                 setError([error.data])
             }
         })
    }
    const columns = React.useMemo(
        () => [
            {
            Header: 'Nome',
            accessor: 'nome',
            },
            {
            Header: 'Descrizione',
            accessor: 'descrizione',
            }
        ],
        []
      )

    const handleChangeFiltra = (name , value) => {
        setInputs(values => ({ ...values, [name]: value }))
        filtra({[name]: value})
    }

    const filtra = (param)=> {
        gruppi_fetch.readGruppo({...inputs ,...param})
        .then(response => {
            setDatiGruppi(response.data)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");;
            }
        })
    }

    const handleChangeCrea = (name , value) => {
        setDatiCrea(values => ({ ...values, [name]: value }))
    }

    const onChangeViewGruppo = (id) => {
        gruppi_fetch.readGruppo({id : id})
        .then(response => {
            setDatiView(response.data[0]);
            setViewView(true)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");;
            }else{
                setError(error.response.data)
            }
        })
    }

    const creaGruppi = async (stato) => {
        if (stato) {
            await gruppi_fetch.creaGruppo(datiCrea)
            .then(response => {
                readgruppi()
                setViewCrea(false)
                setDatiCrea({})
            })
            .catch(error=> {

                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)

                }
                //console.log(error.response.data , ' error');
            })
        } else {
            setViewCrea(false)
        }

    }

    const onChangeEditGruppo = async (id) => {
        await gruppi_fetch.readGruppo({id:id})
        .then(response => {
           // console.log(response.data[0]);
            setDatiModifica(response.data[0]);
            setViewModifica(true)
        })
    }
    
    const handleChangeModifica = (name , value) => {
        setDatiModifica(values => ({ ...values, [name]: value }))
    }

    const modificaGruppi = async (stato) =>{
        if (stato) {
            await gruppi_fetch.updateGruppo(datiModifica)
            .then(response=> {
                //console.log(response.data ,' response modifica');
                readgruppi()
                setViewModifica(false)
            })
            .catch(error => {
                //console.log(error , ' errori modifica');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)

                }
            })
        }else {
            setViewModifica(false)
        }
    }

    const onChangeAddUserGroup = async (id, nome) => {
        const users = []
        await gruppi_fetch.readAssGruppoUser({gruppoId : id})
        .then(response=> {
            const array = response.data;
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (element.stato) {
                    users.push({id : element.userId})
                }
            }
        })
        .catch(error => {
            //console.log(error , ' error add user');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
        setViewAddUser({
            id : id ,
            nome : nome ,
            utentiDaEscludere : users
        })
    }

    const handleChangeAddUserGroup = async (value , nome) => {
        setDatiAddUser({
            id : value ,
            nome : nome
        })
        setViewAddUserSelectUser(false)
    }

    const adduserGroup = async (stato)=> {
        if (stato) {
            await gruppi_fetch.assUserGruppo({
                userId : datiAddUser.id ,
                gruppoId : viewAddUser.id ,
                stato : 1
            })
            .then(response => {
                //console.log(response.data , ' response add user');
                setViewAddUser(false)
                setDatiAddUser({})
            })
            .catch(error => {
                //console.log(error , ' error add user');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)
    
                }
            })
        }else {
            setViewAddUser(false)
        }
    }

    const Pulsanti = (id) => {
        //console.log(id.id.original.id , 'id');
        return(
            <td >
                <button className='btn py-0 my-0 button_fix' onClick={()=>onChangeViewGruppo(id.id.original.id)}>
                    <FontAwesomeIcon className=' py-0 my-0  text_bluDue' icon={faEye}/>
                </button>

                <button className='btn py-0 my-0 button_fix' onClick={() =>onChangeEditGruppo(id.id.original.id)}>
                    <FontAwesomeIcon className=' py-0 my-0  text_bluDue' icon={faEdit} />
                </button>

                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0  text_bluDue' icon={faUsers} onClick={() => usersGroup(id.id.original.id)} />
                </button>

                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0  text_bluDue' icon={faUserPlus} onClick={()=>onChangeAddUserGroup(id.id.original.id , id.id.original.nome)} />
                </button>

                <PermessiGruppi gruppoId={id.id.original.id} nomeGruppo={id.id.original.nome} />

                <button className='btn py-0 my-0 button_fix' >
                    {id.id.original.stato ?
                    <FontAwesomeIcon className=' py-0 my-0 text-danger' onClick={()=>setViewDeleteGroup(id.id.original)} icon={faTrash} />
                    :
                    <FontAwesomeIcon className=' py-0 my-0 text-success' onClick={()=>attivaGruppo(id.id.original.id)} icon={faCheck} />
                    }
                </button>
            </td>
        )
    }

    const usersGroup = async (id) => {
        await gruppi_fetch.readAssGruppoUser({gruppoId : id})
        .then(response => {
            //console.log(response.data);
            setDatiViewUsers(response.data)
            setViewViewUsers(id)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
        
    }

    const deleteUserGroup = async (id) => {
        await gruppi_fetch.updateAssGruppoUser({ id : id, stato : 0 })
        .then(response => {
            //console.log(response.data);
            usersGroup(viewViewUsers)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const onCloseDeleteGroup = async (stato) => {
        if (stato) {
            //console.log(viewDeleteGroup.id , 'viewDeleteGroup') 
            await gruppi_fetch.updateGruppo({ id : viewDeleteGroup.id, stato : "0" })
            await readgruppi()
            setViewDeleteGroup(false)
        }else {
            await readgruppi()
            setViewDeleteGroup(false)
        }
    }

    const attivaGruppo = async (id) => {
        await gruppi_fetch.updateGruppo({ id : id, stato : "1" })
        await readgruppi()
    }

    return(
        <div className='p-2'>

            <PaginazioneTitoloPulsanteCrea
              titolo={'Gruppi'}
              funCrea={() =>setViewCrea(true)}
            />

            <div className="p-4 ps-5">
                <div className='border_filtra p-3 mx-4 px-4' >
                    <RicercaCampiGruppi onChange={handleChangeFiltra} values={inputs} errors={{}} classiDiv={'d-flex align-items-center'} />
                    <div className='ps-2'>
                        <PulsantiFiltra
                            filtra={filtra}
                            azzera={readgruppi}
                        />
                    </div>
                </div>
  
                <div style={{paddingTop :'1rem'}}>
                    <Tabella
                        columns={columns}
                        data={datiGruppi}
                        Pulsanti={Pulsanti}
                    />
                </div>
            </div>
            {viewCrea && 
                <QuestionModal
                    title = 'Crea gruppo'
                    text = {
                        <div className='fs-4'>
                            <RicercaCampiGruppi 
                                values={datiCrea} 
                                onChange={handleChangeCrea} 
                                errors={error} 
                                classiDiv={'d-flex align-items-center'} 
                            />
                        </div>
                    }
                    YesLabel = 'Crea'
                    onClose = {creaGruppi}
                />
            }

            {viewView && 
                <InfoModal
                    title='View gruppo'
                    text = {
                        <div className='fs-4'>
                            <p><span className='fw-bold'>Nome : </span>{datiView.nome}</p>
                            <p><span className='fw-bold'>Descrizione : </span>{datiView.descrizione}</p>
                        </div>
                    }
                    canClose={true}
                    handleClose={() =>setViewView(false)}
                />
            }

            {viewModifica && 
                <QuestionModal
                    title = 'Modifica gruppo'
                    text = {
                        <div className='fs-4'>
                            <RicercaCampiGruppi 
                                values={datiModifica} 
                                onChange={handleChangeModifica} 
                                errors={error} 
                                classiDiv={'d-flex align-items-center'} 
                            />
                        </div>
                    }
                    YesLabel = 'Modifica'
                    onClose = {modificaGruppi}
                />
            }       

            {viewAddUser && 
                <QuestionModal
                    title = {'Aggiungi utente al gruppo ' + viewAddUser.nome}
                    text = {
                        <div className='fs-4'>
                            <p>Gruppo : {viewAddUser.nome}  </p>
                            {viewAddUserSelectUser ?
                                <SelezionaUser 
                                    onCancel={()=>setViewAddUserSelectUser(false)} 
                                    onSuccess={(value , nome)=>handleChangeAddUserGroup(value, nome)} 
                                    utentiDaEscludere={viewAddUser.utentiDaEscludere}
                                /> 
                                :
                                <>
                                    <button 
                                        className='btn btn-primary' 
                                        onClick={()=>setViewAddUserSelectUser(true)} 
                                    >
                                        Seleziona utente
                                    </button>
                                    {datiAddUser.nome && <p> utente selezionato : {datiAddUser.nome} </p>}
                                </>
                            }
                        </div>
                    }
                    YesLabel = 'Aggiungi'
                    onClose = {adduserGroup}
                />
            }     

            {viewViewUsers && 
                <InfoModal
                    title='Utenti nel gruppo'
                    text = {
                        <ul className='fs-4'>
                            {datiViewUsers.map((item , key) => {
                                return(
                                    <>
                                        {item.stato ?<li>
                                            {item.userAn.username}
                                            <button
                                                className='btn'
                                                onClick={()=>deleteUserGroup(item.id)}
                                            >
                                                <FontAwesomeIcon icon={faUserXmark} className='text-danger' />
                                            </button>
                                        </li> : ''}
                                    </>
                                )
                            })}
                        </ul>
                    }
                    canClose={true}
                    handleClose={() =>setViewViewUsers(false)}
                />
            }  

            {!!viewDeleteGroup && <QuestionModal
                title='Disabilita gruppo'
                text={'Vuoi disabilitare il gruppo '+viewDeleteGroup.nome+' ?'}
                onClose={onCloseDeleteGroup}
            />}
            
        </div>
    )
}