import React from 'react';

export default function SwitchOr ({
    stato , 
    onChangeValue,  
    number , 
    label ,
    classe ,
    width = '15%'
}) {
    //console.log(stato , ' stato ', number );
    
    const handleCambiavalore = () => onChangeValue(stato ? 0 : 1, number);

    return (
        <>
            <div className={"form-check form-switch d-flex justify-content-between align-items-center " + classe} style={{width : width , marginBottom : '1rem'}}>
                <label className="form-check-label" htmlFor={"customSwitch"+number}>{label}</label>
                <input checked={!!stato} onChange={handleCambiavalore} className="form-check-input"
                 type="checkbox" role="switch" id={"customSwitch"+number}/>
            </div>
        </>
    )
}