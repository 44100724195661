import React , { useEffect, useState } from "react";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import Campi from "./Campi";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import catProd_fetch from "../fetch/catProd_fetch";
import Tabella from "../riutilizabili/Tabella";
import QuestionModal from "../riutilizabili/QuestionModal";



export default function View() {
    const [inputs , setInputs] = useState({})
    const [inputsFiltra , setInputsFiltra] = useState({})
    const [viewCrea , setViewCrea] = useState(false);
    const [errors , setErrors] = useState({})
    const [array , setArray] = useState([])
    const [disablePulsanteCrea , setDisablePulsanteCrea] = useState(true)

    useEffect(()=> {
        filtra()
    },[])

    const filtra = async (filtri) => {
        const result = await catProd_fetch.get(filtri);
        setArray(result.data)
    }

    const onCloseCreate = async (stato) => {
        if (stato) {
            const result = await catProd_fetch.create(inputs);
            if (result.status === 200) {
                await filtra()
                setViewCrea(false)
                setErrors({})
            }else {
                setErrors(result.data)
            }
        } else {
            setViewCrea(false)
            
        }
        setInputs({})
    }

    const columns = React.useMemo(
        () => [
            {
            Header: 'Nome',
            accessor: 'nome',
            },
            {
            Header: 'Articoli assegnati',
            accessor: 'prodottiCat',
            Cell : ({row : { original}}) => {
                console.log(original , 'original');
                return(
                    <>
                        {/* {original.prodottiCat.length > 0 ? <>
                            {original.prodottiCat.map(item =>{
                                return(
                                <span className="p-1" > {item.nome} </span> 
                                )
                            })}
                        </>
                        :
                        <>Nessun articolo assegnato</>
                        } */}

                        {original.prodottiCat.length}
                    </>
                )
            }
            },
            {
            Header: 'Categoria padre',
            accessor: 'catPadre',
            Cell : ({row : { original}}) => {
                console.log(original , 'original');
                return(
                    <>
                        {original?.infoSottoCategoria?.nome}
                    </>
                )
            }
            },

        ],
        []
        )
    
    const onChangeFiltra = (nome , valore) => {
        setInputsFiltra(values => ({ ...values, [nome]: valore }))
        filtra({...inputsFiltra, [nome]: valore})
    }
    

    return(
    <div className="p-2" >
        <PaginazioneTitoloPulsanteCrea titolo='Categorie articoli' funCrea={()=>setViewCrea(true)} />

        <div className="p-4 ps-5">
            <div className='border_filtra p-3 mx-4 px-4' >
                <Campi values={inputsFiltra}  onChange={onChangeFiltra} errors />
                <PulsantiFiltra azzera={()=>{filtra() ; setInputsFiltra({})}} filtra={()=>filtra(inputsFiltra)}  />
            </div>

            <div style={{paddingTop :'1rem'}}>
                <Tabella
                    data={array}
                    columns={columns}
                />
            </div>
        </div>


        {!!viewCrea && <QuestionModal
            title='Crea categoria articolo'
            text={
                <Campi 
                    values={inputs}  
                    onChange={(nome,valore)=>setInputs(values => ({ ...values, [nome]: valore }))} 
                    errors
                />
            }
            onClose={onCloseCreate}
        />}
    </div>
    )
}